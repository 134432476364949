import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import howToUseImage from '../../../../images/howToUse.png';
import { Title } from "../../../../components/Topography/Title";
import { FlexBox } from "../../../../../../components/FlexBox";

export const HowToUseWelcomeComponent: FC = () => {
    return (
        <div className={classes.howToUseImage} style={{
            backgroundImage: `url(${howToUseImage})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        }}>
            <FlexBox justify='center' style={{ width: '100%' }} className={classes.title}>
                <Title size='large' weight='bold'>
                    How to use
                </Title>
            </FlexBox>
        </div>
    );
}