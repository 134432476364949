import React, { FC, ReactNode } from 'react';
import classes from './styles/index.module.scss';
import { PaddingBox } from "../PaddingBox";
import { FlexBox } from "../FlexBox";
import classNames from "classnames";

type DottedBoxType = {
    column?: boolean;
    children?: ReactNode;
    className?: string;
}

export const DottedBox: FC<DottedBoxType> = (
    { column = true, children, className }
) => {
    const dottedBoxClasses = classNames(classes.dottedBox, className);

    return (
        <PaddingBox small className={dottedBoxClasses}>
            <FlexBox column={column} justify='space-around' align='middle' style={{ height: '100%' }}>
                {children}
            </FlexBox>
        </PaddingBox>
    );
}