import React from "react";
import { SUPPORT_EMAIL } from "../../const/const";

export const aboutUsParagraphs = [
    <>
        CaptureScreen Inc. is a technology company dedicated to providing efficient and user-friendly
        tools for digital content creation and management. We are the proud developers of the
        CaptureScreen browser extension, a robust and intuitive solution for screen capturing and recording.
    </>,
    <>
        Founded in 2023 and headquartered in San Francisco, our team comprises tech enthusiasts,
        software engineers, and UX designers, all committed to delivering a reliable and seamless
        experience for our users. We understand the evolving demands of the digital age,
        and our solutions are designed to meet the needs of a wide range of users,
        from students and teachers to professionals and creatives.
    </>,
    <>
        Our flagship product, CaptureScreen, offers high-quality screenshot and screen
        recording functionalities right in your browser. With a focus on user privacy and data security,
        CaptureScreen stores all data locally on your device, giving you complete control over your content.
    </>,
    <>
        We believe in continuous learning and innovation, and we're always working on updates and
        features to enhance the CaptureScreen experience. We are proud to serve millions of users worldwide and
        to help them capture, create, and share digital content effortlessly.
    </>,
    <>
        At CaptureScreen Inc., we stand by our commitment to providing reliable, user-friendly,
        and secure digital tools. We're here to help you capture your screen, your way.
    </>,
    <>
        For more information about CaptureScreen or for support, please feel
        free to contact us at&nbsp;<a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
        We'd love to hear from you!
    </>,
];