import React, { FC, ReactNode } from 'react';
import classes from './styles/index.module.scss';
import { FlexBox } from "../../../../components/FlexBox";
import classNames from "classnames";

type PopupContainerType = {
    children: ReactNode;
    className?: string;
}

export const PopupContainer: FC<PopupContainerType> = ({ className, children }) => {
    const containerClasses = classNames(classes.popupContainer, className);

    return (
        <FlexBox column justify='space-between' className={containerClasses}>
            {children}
        </FlexBox>
    );
}