import React, { FC, ReactNode } from 'react';
import classes from './styles/index.module.scss';
import classNames from "classnames";
import { PaddingBox } from "../PaddingBox";
import { FlexBox } from "../FlexBox";

type BigButtonType = {
    children: ReactNode,
    className?: string,
    onClick?: () => void,
    disabled?: boolean,
    style?: React.CSSProperties,
}

export const BigButton: FC<BigButtonType> = (
    {
        children,
        className,
        onClick,
        disabled,
        style,
    }
) => {
    const buttonClasses = classNames(className, classes.button, {
        [classes.disabled]: disabled,
    });

    const handleClick = () => {
        !disabled && onClick && onClick();
    };

    return (
        <div className={buttonClasses} onClick={handleClick} style={style}>
            <PaddingBox small>
                <FlexBox justify='center' align='stretch'>
                    {children}
                </FlexBox>
            </PaddingBox>
        </div>
    );
}