import React, { FC, ReactNode } from 'react';
import classes from './styles/index.module.scss';
import classNames from "classnames";
import { useResizeContext } from "../../providers/ResizeProvider/ResizeProvider";

type LinkButtonType = {
    children: ReactNode;
    isSelected?: boolean;
    style?: React.CSSProperties;
}

export const LinkButton: FC<LinkButtonType> = ({ children, isSelected, style }) => {
    const { isMobile } = useResizeContext();
    const buttonClasses = classNames({
        [classes.buttonDesktop]: !isMobile,
        [classes.buttonMobile]: isMobile,
        [classes.selected]: isSelected,
    });

    return (
        <div className={buttonClasses} style={style}>
            {children}
        </div>
    );
}