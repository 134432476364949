import React, { FC } from 'react';
import { SVG } from "../../../icons/SVG";

export const Arrow: FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <SVG {...props} width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Arrow 10" clipPath="url(#clip0_194_5314)">
            <path id="Arrow 10_2" d="M47.4353 53.2372C46.3529 52.1548 45.1622 50.9641 44.0798 49.8817C34.6628 40.2478 25.2459 30.3974 15.8289 20.7636C14.7464 19.6811 13.7722 18.7069 12.7982 17.5161C11.4994 16.0005 10.0927 13.9434 12.2602 12.4254C13.1273 11.7748 15.6181 12.5313 16.7007 13.3971C25.1443 20.9735 33.3713 28.5501 41.7065 36.2349C43.655 37.9667 45.3868 39.9152 48.3107 40.888C47.4467 37.4223 46.2575 34.0652 45.6101 30.5994C45.2863 28.9748 45.8298 26.2664 47.0219 25.5073C49.4061 23.9891 51.3541 26.3708 52.0027 28.3201C55.6789 37.6331 59.4634 47.0543 62.3812 56.6928C64.2182 62.8658 60.9662 66.1177 54.7933 64.2807C46.5626 61.9035 38.6577 58.3346 30.7525 55.1989C29.7778 54.8747 29.4542 53.0335 28.805 51.9507C29.997 51.4083 31.2977 50.1075 32.3808 50.3233C34.7637 50.5383 37.146 51.6198 39.5286 52.268C42.0194 53.0244 44.5103 53.7809 46.8929 54.4291C46.8932 53.9959 47.1101 53.5624 47.4353 53.2372Z" fill="#FC0404"/>
        </g>
        <defs>
            <clipPath id="clip0_194_5314">
                <rect width="34.7984" height="71.0467" fill="white" transform="translate(0 24.6062) rotate(-45)"/>
            </clipPath>
        </defs>
    </SVG>
);