import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

type ResizeStateValueType = {
    isMobile: boolean,
}

const ResizeContext = createContext(undefined as unknown as ResizeStateValueType);

export function useResizeContext () {
    return useContext(ResizeContext);
}

type ResizeProviderType = {
    children: ReactNode;
}

export const ResizeProvider: FC<ResizeProviderType> = ({ children }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 920);

    const resizeEvent = useCallback(() => {
        setIsMobile(window.innerWidth < 920);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', resizeEvent);
        return () => window.removeEventListener('resize', resizeEvent);
    }, []);

    return (
        <ResizeContext.Provider value={{isMobile}}>
            {children}
        </ResizeContext.Provider>
    );
}