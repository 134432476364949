import { TitleSize, TitleWeight } from "./const";
import { DetailedHTMLProps, HTMLAttributes } from "react";

export enum TopographyKeys {
    Size = 'size',
    Weight = 'weight',
    Secondary = 'secondary',
}

export type TopographyProps = {
    [TopographyKeys.Size]?: keyof typeof TitleSize;
    [TopographyKeys.Weight]?: keyof typeof TitleWeight;
    [TopographyKeys.Secondary]?: boolean;
};

export type TitleType = {
    level?: 1 | 2 | 3 | 4 | 5 | 6;
} & DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & TopographyProps;

export type UListPropsType = {
    isMarkerHidden?: boolean;
    markType?: 'initial'
} & DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement> & TopographyProps;

export type ParagraphPropsType = {} & DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> & TopographyProps;