import React, { FC } from 'react';
import { SVG } from "../../../icons/SVG";

export const GoogleChromeIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <SVG {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" isIcon>
        <g clipPath="url(#clip0_582_4363)">
            <path d="M12.0003 17.9997C15.3141 17.9997 18.0005 15.3133 18.0005 11.9994C18.0005 8.68548 15.3141 5.99899 12.0003 5.99899C8.68636 5.99899 6 8.68548 6 11.9994C6 15.3133 8.68636 17.9997 12.0003 17.9997Z" fill="white"/>
            <path d="M6.80378 15.0009L1.60819 6.00189C0.554709 7.82602 0 9.89531 0 12.0019C0 14.1085 0.554457 16.1778 1.60781 18.0019C2.66117 19.826 4.17625 21.3409 6.00063 22.3937C7.82501 23.4466 9.89455 24.0006 12.001 24L17.1966 15.0009V14.9996C16.6704 15.9122 15.9131 16.6701 15.0009 17.1971C14.0888 17.7241 13.054 18.0016 12.0006 18.0017C10.9472 18.0019 9.91234 17.7247 9.00006 17.198C8.0878 16.6712 7.33031 15.9136 6.80378 15.0012V15.0009Z" fill="#229342"/>
            <path d="M17.1962 15.0006L12.0007 23.9996C14.1072 24 16.1766 23.4455 18.0009 22.3924C19.8251 21.3393 21.3399 19.8244 22.3931 18C23.4461 16.1756 24.0003 14.1062 24 11.9997C23.9996 9.89329 23.4446 7.824 22.3909 6H11.9999L11.9986 6.00088C13.052 6.00038 14.087 6.27726 14.9994 6.80366C15.9119 7.33015 16.6696 8.08743 17.1965 8.99969C17.7233 9.91191 18.0006 10.9468 18.0006 12.0002C18.0006 13.0536 17.7232 14.0885 17.1963 15.0007L17.1962 15.0006Z" fill="#FBC116"/>
            <path d="M12.0002 16.751C14.6237 16.751 16.7505 14.6243 16.7505 12.0006C16.7505 9.37701 14.6237 7.2504 12.0002 7.2504C9.37674 7.2504 7.25 9.37714 7.25 12.0006C7.25 14.6241 9.37674 16.751 12.0002 16.751Z" fill="#1A73E8"/>
            <path d="M12.0002 6.00038H22.3913C21.3383 4.176 19.8236 2.66091 17.9994 1.60756C16.1752 0.554277 14.1058 -0.000159884 11.9994 3.45845e-08C9.89291 0.000126019 7.82361 0.554838 5.99961 1.60844C4.17555 2.66199 2.66101 4.17721 1.60831 6.00176L6.8039 15.0008L6.80528 15.0016C6.27814 14.0895 6.00043 13.0548 6.0001 12.0013C5.99977 10.9479 6.27682 9.91295 6.80339 9.00057C7.32988 8.08819 8.08755 7.33052 8.9998 6.8039C9.91206 6.27729 10.947 6 12.0005 6.00025L12.0002 6.00038Z" fill="#E33B2E"/>
        </g>
        <defs>
            <clipPath id="clip0_582_4363">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </SVG>
);