import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import { Button } from "../../../../components/Button";
import { FlexBox } from "../../../../components/FlexBox";
import { BrandIcon } from "../../../../icons/BrandIcon";
import { Text } from "../../../../components/Text";
import { RouteURLs } from "../../const/RouteURLs";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useResizeContext } from "../../providers/ResizeProvider/ResizeProvider";
import { PaddingBox } from "../../../../components/PaddingBox";

type LogoButtonType = {
    isColoredText?: boolean;
    isWhiteText?: boolean;
    isIcon?: boolean;
    isTitle?: boolean;
    title?: string;
    href?: string;
}

export const LogoButton: FC<LogoButtonType> = (
    {
        isColoredText,
        isWhiteText,
        isIcon = true,
        isTitle = true,
        title = 'Capture Screen',
        href = RouteURLs.Home,
    }
) => {
    const { isMobile } = useResizeContext();
    const titleClasses = classNames({
        [classes.title_white]: isWhiteText,
        [classes.title]: isColoredText,
        [classes.title_desktop]: !isMobile,
        [classes.title_mobile]: isMobile,
    });

    return (
        <Link to={href}>
            <Button link={!isWhiteText} isColoredIcon black={isWhiteText} paddingLess>
                <PaddingBox middleRight>
                    <FlexBox align='middle' gap='small'>
                        {isIcon && (
                            <BrandIcon/>
                        )}
                        {isTitle && (
                            <Text className={titleClasses} weight='bold'>
                                {title}
                            </Text>
                        )}
                    </FlexBox>
                </PaddingBox>
            </Button>
        </Link>
    );
}