import { TopographyProps } from "../types";
import classNames from "classnames";
import classes from "../styles/index.module.scss";
import { TitleSize, TitleWeight } from "../const";

export const useTopographyClasses = <T extends TopographyProps>(
    {
        size = TitleSize.middle,
        weight = TitleWeight.regular,
        secondary,
    }: T
) => {
    return classNames(
        classes[`size_${size}`],
        classes[`weight_${weight}`],
        { [classes.secondary]: secondary, }
    );
}