import React, { FC } from 'react';
import { FlexBox } from "../../../../../../components/FlexBox";
import { InfoContainer } from "../../../../components/InfoContainer";
import { Title } from "../../../../components/Topography/Title";
import { ImageContainer } from "../../../../components/ImageContainer";
import blog1 from '../../../../images/blog1.png';
import blog2 from '../../../../images/blog2.svg';
import blog3 from '../../../../images/blog3.svg';
import blog4 from '../../../../images/blog4.png';
import blog5 from '../../../../images/blog5.png';
import blog6 from '../../../../images/blog6.png';
import blog7 from '../../../../images/blog7.svg';
import blog8 from '../../../../images/blog8.png';
import { Paragraph } from "../../../../components/Topography/Paragraph";
import { UList } from "../../../../components/Topography/UList";
import { blogTexts } from "./const";

export const BlogDescription: FC = () => {
    return (
        <FlexBox justify='center'>
            <InfoContainer>
                <FlexBox column gap='extraLarge'>
                    <FlexBox column gap='middle'>
                        <Title level={3} size='normal' weight='bold'>
                            {blogTexts[0].title}
                        </Title>
                        <ImageContainer>
                            <img src={blog1} alt=""/>
                        </ImageContainer>
                        <Paragraph size='small' weight='regular'>
                            {blogTexts[0].p[0]}
                        </Paragraph>
                        <Paragraph size='small' weight='regular'>
                            {blogTexts[0].p[1]}
                        </Paragraph>
                    </FlexBox>
                    <FlexBox column gap='middle'>
                        <Title level={3} size='normal' weight='bold'>
                            {blogTexts[1].title}
                        </Title>
                        <ImageContainer>
                            <img src={blog2} alt=""/>
                        </ImageContainer>
                        <Paragraph size='small' weight='regular'>
                            {blogTexts[1].p[0]}
                        </Paragraph>
                    </FlexBox>
                    <FlexBox column gap='middle'>
                        <Title level={3} size='normal' weight='bold'>
                            {blogTexts[2].title}
                        </Title>
                        <ImageContainer>
                            <img src={blog3} alt=""/>
                        </ImageContainer>
                        <Paragraph size='small' weight='regular'>
                            {blogTexts[2].p[0]}
                        </Paragraph>
                        <UList size='small' weight='regular' isMarkerHidden>
                            <FlexBox column gap='middle'>
                                {blogTexts[2].ul?.map(li => (
                                    <li>
                                        {li}
                                    </li>
                                ))}
                            </FlexBox>
                        </UList>
                    </FlexBox>
                    <FlexBox column gap='middle'>
                        <Title level={3} size='normal' weight='bold'>
                            {blogTexts[3].title}
                        </Title>
                        <Paragraph size='small' weight='regular'>
                            {blogTexts[3].p[0]}
                        </Paragraph>
                        <Title level={4} size='middle' weight='bold'>
                            {blogTexts[3].topic![0].title}
                        </Title>
                        <ImageContainer>
                            <img src={blog4} alt=""/>
                        </ImageContainer>
                        <UList size='small' weight='regular' isMarkerHidden>
                            <FlexBox column gap='middle'>
                                {blogTexts[3].topic![0].ul.map(li => (
                                    <li>
                                        {li}
                                    </li>
                                ))}
                            </FlexBox>
                        </UList>
                        <Title level={4} size='middle' weight='bold'>
                            {blogTexts[3].topic![1].title}
                        </Title>
                        <ImageContainer>
                            <img src={blog5} alt=""/>
                        </ImageContainer>
                        <UList size='small' weight='regular' isMarkerHidden>
                            <FlexBox column gap='middle'>
                                {blogTexts[3].topic![1].ul.map(li => (
                                    <li>
                                        {li}
                                    </li>
                                ))}
                            </FlexBox>
                        </UList>
                        <Paragraph size='small' weight='regular'>
                            {blogTexts[3].topic![1].p![0]}
                        </Paragraph>
                    </FlexBox>
                    <FlexBox column gap='middle'>
                        <Title level={3} size='normal' weight='bold'>
                            {blogTexts[4].title}
                        </Title>
                        <ImageContainer>
                            <img src={blog6} alt=""/>
                        </ImageContainer>
                        <Paragraph size='small' weight='regular'>
                            {blogTexts[4].p[0]}
                        </Paragraph>
                        <Paragraph size='small' weight='regular'>
                            {blogTexts[4].p[1]}
                        </Paragraph>
                    </FlexBox>
                    <FlexBox column gap='middle'>
                        <Title level={3} size='normal' weight='bold'>
                            {blogTexts[5].title}
                        </Title>
                        <ImageContainer>
                            <img src={blog7} alt=""/>
                        </ImageContainer>
                        <Paragraph size='small' weight='regular'>
                            {blogTexts[5].p[0]}
                        </Paragraph>
                        <Paragraph size='small' weight='regular'>
                            {blogTexts[5].p[1]}
                        </Paragraph>
                    </FlexBox>
                    <FlexBox column gap='middle'>
                        <Title level={3} size='normal' weight='bold'>
                            {blogTexts[6].title}
                        </Title>
                        <ImageContainer>
                            <img src={blog8} alt=""/>
                        </ImageContainer>
                        <Paragraph size='small' weight='regular'>
                            {blogTexts[6].p[0]}
                        </Paragraph>
                        <Paragraph size='small' weight='regular'>
                            {blogTexts[6].p[1]}
                        </Paragraph>
                        <Paragraph size='small' weight='regular'>
                            {blogTexts[6].p[2]}
                        </Paragraph>
                    </FlexBox>
                </FlexBox>
            </InfoContainer>
        </FlexBox>
    );
}