import React, { FC, useMemo } from 'react';
import { FlexBox } from "../../../../components/FlexBox";
import { Title } from "../Topography/Title";
import { Collapse as CollapseAntd } from "antd";
import { CollapseProps } from "antd/es/collapse/Collapse";
import { Paragraph } from "../Topography/Paragraph";

type CollapseType = CollapseProps;

export const Collapse: FC<CollapseType> = (
    { items, ...props }
) => {
    const newItems = useMemo(() => {
        return items?.map(item => {
            return {
                ...item,
                label: (
                    <FlexBox align='middle' style={{ height: 'auto' }}>
                        <Title size='small' weight="bold" level={4}>
                            {item.label}
                        </Title>
                    </FlexBox>
                ),
                children: (
                    <Paragraph size='small' weight='regular' secondary>{item.children}</Paragraph>
                ),
            };
        });
    }, [items]);

    return (
        <CollapseAntd
            {...props}
            items={newItems}
            expandIconPosition={'end'}
            bordered={false}
        />
    );
}