import React, { FC } from 'react';
import { SVG } from "./SVG";

export const BrandIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <SVG {...props} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_582_4345)">
            <rect width="48" height="48" rx="8.25" fill="#D9D9D9"/>
            <rect width="48" height="48" rx="8.25" fill="url(#paint0_radial_582_4345)"/>
            <rect width="48" height="48" rx="8.25" fill="url(#paint1_radial_582_4345)"/>
            <path d="M19.654 43.526L28.62 28L35.684 40.234C32.2832 42.6887 28.1941 44.0067 24 44C22.508 44 21.054 43.836 19.654 43.526ZM15.78 42.24C13.2104 41.0782 10.9148 39.3875 9.04282 37.2785C7.17085 35.1694 5.76465 32.6893 4.91597 30H22.846L15.78 42.238V42.24ZM4.09997 26C3.81376 23.1945 4.12625 20.3602 5.01679 17.6845C5.90733 15.0087 7.35563 12.5525 9.26597 10.478L18.224 26H4.09997ZM12.318 7.76602C15.7182 5.31178 19.8065 3.9938 24 4.00002C25.492 4.00002 26.946 4.16402 28.346 4.47402L19.38 20L12.318 7.76602ZM32.22 5.76002C34.7896 6.92179 37.0853 8.61239 38.9573 10.7215C40.8293 12.8305 42.2354 15.3107 43.084 18H25.154L32.22 5.76002ZM43.9 22C44.1862 24.8055 43.8737 27.6398 42.9832 30.3156C42.0926 32.9913 40.6443 35.4476 38.734 37.522L29.776 22H43.904H43.9Z" fill="white"/>
        </g>
        <defs>
            <radialGradient id="paint0_radial_582_4345" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.75 51.6969) rotate(-90) scale(47.5716 44.2453)">
                <stop stopColor="#C71A4B"/>
                <stop offset="0.5" stopColor="#EB2D48"/>
                <stop offset="1" stopColor="#8B04FB"/>
            </radialGradient>
            <radialGradient id="paint1_radial_582_4345" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-8.04019 3.45769) rotate(78.681) scale(21.2648 87.654)">
                <stop stopColor="#2304FB"/>
                <stop offset="1" stopColor="#2304FB" stopOpacity="0"/>
            </radialGradient>
            <clipPath id="clip0_582_4345">
                <rect width="48" height="48" fill="white"/>
            </clipPath>
        </defs>
    </SVG>
);