import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import classNames from "classnames";

type ImageContainerType = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const ImageContainer: FC<ImageContainerType> = (
    { className, ...props }
) => {
    const containerClasses = classNames(className, classes.imageContainer);

    return (
        <div className={containerClasses} {...props}/>
    );
}