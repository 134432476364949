import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import { FlexBox } from "../../../../components/FlexBox";
import { LogoButton } from "../LogoButton";
import { Button } from "../../../../components/Button";
import { Text } from "../../../../components/Text";
import { Link } from "react-router-dom";
import { RouteURLs } from "../../const/RouteURLs";
import { Paragraph } from "../Topography/Paragraph";
import { useResizeContext } from "../../providers/ResizeProvider/ResizeProvider";
import { Letter } from "../../icons/Letter";
import { SUPPORT_EMAIL } from "../../const/const";

export const Footer: FC = () => {
    const { isMobile } = useResizeContext();
    return (
        <footer className={classes.footer}>
            <FlexBox align='middle' justify='space-evenly' column={isMobile} gap='large'>
                <LogoButton isWhiteText/>
                <FlexBox gap='small' column={isMobile} align='middle'>
                    <Link to={RouteURLs.AboutUs}>
                        <Button black>
                            <Text>About Us</Text>
                        </Button>
                    </Link>
                    <Link to={RouteURLs.PrivacyPolicy}>
                        <Button black>
                            <Text>Privacy Policy</Text>
                        </Button>
                    </Link>
                    <Link to={RouteURLs.TermsOfService}>
                        <Button black>
                            <Text>Terms of Service</Text>
                        </Button>
                    </Link>
                </FlexBox>
                <Link to={`mailto:${SUPPORT_EMAIL}`} style={{ width: isMobile ? '100%' : "auto" }}>
                    <Button white block={isMobile}>
                        <Letter/>
                        <Text>Contact Us</Text>
                    </Button>
                </Link>
            </FlexBox>
            <FlexBox justify='center'>
                <Paragraph
                    secondary size='tiny'
                    weight='regular'
                    style={{
                        paddingTop: isMobile ? '24px' : '72px',
                        textAlign: 'center',
                    }}
                >
                    Copyright © 2023 CaptureScreen. All rights reserved.
                </Paragraph>
            </FlexBox>
        </footer>
    );
}