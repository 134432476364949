import React, { FC } from 'react';
import { FlexBox } from "../../../../../../components/FlexBox";
import { Text } from "../../../../../../components/Text";
import { AccentedText } from "../../../../../../components/AccentedText";
import { RecordPageCheckboxes } from "../../../../../Popup/pages/RecordPage/RecordPageCheckboxes";
import { Dropdown } from "../../../../../../components/Dropdown";
import { CameraPickOff } from "../../../../../../icons/CameraPickOff";
import { MicrophonePickOff } from "../../../../../../icons/MicrophonePickOff";
import { SpeakerPickOff } from "../../../../../../icons/SpeakerPickOff";
import { SwitchBox } from "../../../../../../components/SwitchBox";
import { PencilRuler } from "../../../../../../icons/PencilRuler";
import { HDIcon } from "../../../../../../icons/HDIcon";
import { Button } from "../../../../../../components/Button";
import { CircleFill } from "../../../../../../icons/CircleFill";
import { PopupContainer } from "../../../../components/PopupContainer";
import { CheckboxValue } from "../../../../../Popup/pages/RecordPage/const";
import { PopupSegmentedOptionValue } from "../../../../../Popup/const";
import { Camera, History, Video } from "../../../../../../icons";
import { Tabs } from "../../../../../../components/Tabs";
import { PaddingBox } from "../../../../../../components/PaddingBox";
import classes from './styles/index.module.scss';
import { SectionImageContainer } from "../../../../components/SectionImageContainer";

export const PopupRecord: FC = () => {
    return (
        <SectionImageContainer>
            <PopupContainer className={classes.popupRecord}>
                <Tabs
                    selectedId={PopupSegmentedOptionValue.Record}
                    items={[
                        {
                            id: PopupSegmentedOptionValue.Camera,
                            buttonContent: (
                                <>
                                    <Camera/>
                                    <Text size='small'>Screenshot</Text>
                                </>
                            ),
                            content: null,
                            blocked: true,
                        },
                        {
                            id: PopupSegmentedOptionValue.Record,
                            buttonContent: (
                                <>
                                    <Video/>
                                    <Text size='small'>Video</Text>
                                </>
                            ),
                            content: (
                                <PaddingBox normalHR normalTop>
                                    <FlexBox column gap='medium' justify='center'>
                                        <Text weight='bold' style={{ fontSize: '20px' }}>
                                            <span>Capture a </span>
                                            <AccentedText>Screen Record</AccentedText>
                                        </Text>
                                        <RecordPageCheckboxes
                                            checkbox={CheckboxValue.DesktopWebcam}
                                            setCheckbox={() => {}}
                                        />
                                        <FlexBox justify='space-between' align='middle'>
                                            <Dropdown
                                                values={[]}
                                                icon={<CameraPickOff/>}
                                                setCurrentId={() => {}}
                                                isCurrentTitleHidden
                                                disabled
                                            />
                                            <Dropdown
                                                values={[]}
                                                icon={<MicrophonePickOff/>}
                                                setCurrentId={() => {}}
                                                isCurrentTitleHidden
                                                disabled
                                            />
                                            <Dropdown
                                                values={[]}
                                                icon={<SpeakerPickOff/>}
                                                setCurrentId={() => {}}
                                                isCurrentTitleHidden
                                                title={'Tab Sound'}
                                                disabled
                                            />
                                        </FlexBox>
                                        <SwitchBox
                                            title='Control Bar'
                                            icon={<PencilRuler/>}
                                            checked={true}
                                            disabled
                                        />
                                        <FlexBox justify='space-between' align='middle' wrap>
                                            <Dropdown
                                                values={[{
                                                    title: '1080p',
                                                    id: '1080p'
                                                }]}
                                                icon={<HDIcon/>}
                                                currentId={'1080p'}
                                                setCurrentId={() => {}}
                                                disabled
                                            />
                                            <Button style={{ width: '134px' }}>
                                                <CircleFill/>
                                                <Text>
                                                    Record
                                                </Text>
                                            </Button>
                                        </FlexBox>
                                    </FlexBox>
                                </PaddingBox>
                            )
                        },
                        {
                            id: PopupSegmentedOptionValue.Story,
                            buttonContent: (
                                <>
                                    <History/>
                                    <Text size='small'>History</Text>
                                </>
                            ),
                            content: null,
                            blocked: true,
                        },
                    ]}
                />
            </PopupContainer>
        </SectionImageContainer>
    );
}