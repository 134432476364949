export enum TitleSize {
    tiny = 'tiny',              // 14px
    small = 'small',            // 18px
    middle = 'middle',          // 20px
    normal = 'normal',          // 24px
    big = 'big',                // 40px
    large = 'large',            // 64px
    extraLarge = 'extraLarge',  // 96px
}

export enum TitleWeight {
    regular = 'regular',        // 400
    bold = 'bold',              // 700
    extraBold = 'extraBold',    // 800
}
