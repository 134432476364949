import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import classNames from "classnames";

type SectionType = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>

export const Section: FC<SectionType> = (
    { className, ...props }
) => {
    const sectionClasses = classNames(className, classes.section);

    return (
        <section className={sectionClasses} {...props}/>
    );
}