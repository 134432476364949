import React, { CSSProperties, FC } from 'react';
import classes from './styles/index.module.scss';
import { FlexBox } from "../../../../../../components/FlexBox";
import cameraImage1 from '../../../../images/camera_1.png';
import cameraImage2 from '../../../../images/camera_2.png';
import { PaddingBox } from "../../../../../../components/PaddingBox";
import { Link } from "react-router-dom";
import { RouteURLs } from "../../../../const/RouteURLs";
import { Button } from "../../../../../../components/Button";
import { EdgeIcon } from "../../../../icons/EdgeIcon";
import { GoogleChromeIcon } from "../../../../icons/GoogleChromeIcon";
import { Text } from "../../../../../../components/Text";
import { useScrollContext } from "../../../../providers/ScrollProvider/useScrollContext";

export const HomePageWelcomeComponent: FC = () => {
    const { scrollTop, clientHeight, clientWidth } = useScrollContext();
    const scale = clientHeight / (clientHeight + scrollTop);
    const translateX = (clientWidth/2) - ((clientWidth/2)*scale);
    const camera1Style: CSSProperties = {
        transform: `scale(${scale}) translateX(${translateX}px)`,
    };
    const camera2Style: CSSProperties = {
        transform: `scale(${scale}) translateX(${-translateX}px)`,
    };

    return (
        <>
            <FlexBox justify='space-between' align='middle' className={classes.container}>
                <div className={classes.camera1} style={camera1Style}>
                    <img src={cameraImage1} alt=''/>
                </div>
                <FlexBox column justify='center' align='middle'>
                    <PaddingBox normalBottom>
                        <h1 className={classes.h1}>
                            Screen Recording Screenshot Tool
                        </h1>
                    </PaddingBox>
                    <PaddingBox normalVR>
                        <h2 className={classes.h2}>
                            Capture, Edit, Download and Share
                        </h2>
                    </PaddingBox>
                    <FlexBox align='middle' gap='small'>
                        <Link to={RouteURLs.AddToEdge} target='_blank'>
                            <Button isColoredIcon light>
                                <EdgeIcon/>
                            </Button>
                        </Link>
                        <Link to={RouteURLs.AddToChrome} target='_blank'>
                            <Button black isColoredIcon className={classes.addToChrome}>
                                <GoogleChromeIcon/>
                                <Text weight='semiBold'>Add to Chrome</Text>
                            </Button>
                        </Link>
                    </FlexBox>
                </FlexBox>
                <div className={classes.camera2} style={camera2Style}>
                    <img src={cameraImage2} alt=''/>
                </div>
            </FlexBox>
            <div className={classes.blank}/>
        </>
    );
}