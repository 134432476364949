import React, { FC, useEffect } from 'react'
import { Route, Routes, useLocation } from "react-router-dom";
import { RouteURLs } from "./const/RouteURLs";
import { Home } from "./pages/Home";
import { HowToUse } from "./pages/HowToUse";
import { Blog } from "./pages/Blog";
import { AboutUs } from "./pages/AboutUs";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { TermsOfService } from "./pages/TermsOfService";
import { Page404 } from "./pages/Page404";

export const WebsiteRoutes: FC = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [location]);

    return (
        <Routes>
            <Route
                path={RouteURLs.Home}
                element={<Home/>}
            />
            <Route
                path={RouteURLs.HowToUse}
                element={<HowToUse/>}
            />
            <Route
                path={RouteURLs.Blog}
                element={<Blog/>}
            />
            <Route
                path={RouteURLs.AboutUs}
                element={<AboutUs/>}
            />
            <Route
                path={RouteURLs.PrivacyPolicy}
                element={<PrivacyPolicy/>}
            />
            <Route
                path={RouteURLs.TermsOfService}
                element={<TermsOfService/>}
            />
            <Route
                path={'*'}
                element={<Page404/>}
            />
        </Routes>
    );
};