import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import cameraImage1 from "../../images/camera_1.png";
import { FlexBox } from "../../../../components/FlexBox";
import { PaddingBox } from "../../../../components/PaddingBox";
import cameraImage2 from "../../images/camera_2.png";
import { Title } from "../../components/Topography/Title";

export const Page404: FC = () => {
    return (
        <FlexBox justify='space-between' align='middle' className={classes.container}>
            <div className={classes.camera1}>
                <img src={cameraImage1} alt=''/>
            </div>
            <FlexBox column justify='center' align='middle'>
                <PaddingBox normalBottom>
                    <Title size='extraLarge'>
                        404
                    </Title>
                </PaddingBox>
                <PaddingBox normalVR>
                    <Title level={2} size='normal'>
                        Page Not Found
                    </Title>
                </PaddingBox>
            </FlexBox>
            <div className={classes.camera2}>
                <img src={cameraImage2} alt=''/>
            </div>
        </FlexBox>
    );
}