import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import classNames from "classnames";

type MainType = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;

export const Main: FC<MainType> = (
    { className, ...props }
) => {
    const mainClasses = classNames(className, classes.main);

    return (
        <main className={mainClasses} {...props}/>
    );
}