import React from "react";
import { SUPPORT_EMAIL } from "../../../../const/const";

export const blogTexts = [
    {
        title: 'CaptureScreen: Simplify Your Digital Life with High-Quality Screen Capture and Recording',
        p: [
            `
                In the age of digital communication, there's no denying the power of visual content.
                Whether you're a student capturing notes from an online lecture,
                a professional demonstrating a process to your team, or a content creator sharing gameplay,
                screen capturing and recording tools are invaluable.
            `,
            `
                Today, we're thrilled to introduce you to CaptureScreen, our browser extension
                designed to make screen capturing and recording simple, secure, and efficient.
            `,
        ],
    },
    {
        title: 'What is CaptureScreen?',
        p: [
            `
                CaptureScreen is a browser extension that brings high-quality
                screenshot and screen recording functionalities to your fingertips.
                With just a few clicks, you can capture a full-page screenshot,
                record your entire screen or a specific window, and much more.
            `,
        ],
    },
    {
        title: 'Features of CaptureScreen',
        p: [
            `
                CaptureScreen isn't your ordinary screen capture tool.
                It comes packed with features designed to enhance your productivity:
            `,
        ],
        ul: [
            `
                1. Screenshot Functionality: Capture your screen in one click.
                Choose from full-page, visible area, or custom area screenshots.
            `,
            `
                2. Screen Recorder: Record your screen with flexibility.
                Capture your entire screen, a specific window, or a browser tab.
                Record system or microphone audio for comprehensive recordings.
            `,
            `
                3. Annotate and Edit: Enhance your screenshots with our integrated editor.
                Add text, draw, highlight, or crop your image before saving.
            `,
            `
                4. Local Storage: With CaptureScreen, your privacy is paramount.
                All your screenshots and recordings are saved locally on your device.
                You choose when and where to share your files.
            `,
            `
                5. Sharing Options: Sharing your captures has never been easier.
                Send your screenshots or recordings via Google Drive, upload
                them to cloud storage, or generate a link for quick sharing.
            `,
        ]
    },
    {
        title: 'Getting Started with CaptureScreen',
        p: [
            `
                Once you've installed the CaptureScreen browser extension, getting started is a breeze.
                Here's a quick rundown of how you can begin using CaptureScreen's features:
            `,
        ],
        topic: [
            {
                title: 'Taking a Screenshot:',
                ul: [
                    `
                        1. Click on the CaptureScreen icon in your browser toolbar.
                    `,
                    `
                        2. Select the 'Screenshot' option from the drop-down menu.
                    `,
                    `
                        3. Choose to capture either a full page, the visible area,
                        or a specific region of the screen.
                    `,
                    `
                        4. If you select a specific region,
                        click and drag your mouse to cover the area you want to capture.
                    `,
                    `
                        5. Once you've made your selection, click 'Capture'.
                    `,
                    `
                        6. From here, you can use the integrated screenshot editor to annotate
                        your screenshot with text or drawings, or crop the image to your liking.
                    `,
                    `
                        7. After making your edits, click 'Save'.
                        Your screenshot will be saved to your local storage.
                    `,
                ]
            },
            {
                title: 'Recording Your Screen:',
                ul: [
                    `
                        1. Click the CaptureScreen icon in your browser toolbar.
                    `,
                    `
                        2. Select the 'Screen Recorder' option from the drop-down menu.
                    `,
                    `
                        3. Choose what you'd like to record: the entire screen, a specific application window, or a browser tab.
                    `,
                    `
                        4. If you want to record audio along with your screen,
                        select the appropriate audio source.
                    `,
                    `
                        5. Hit 'Start Recording' to begin.
                    `,
                    `
                        6. To end the recording, click the CaptureScreen icon again
                        and select 'Stop Recording'. Your screen recording will be saved
                        as a WEBM or MP4 file in your local storage.
                    `,
                ],
                p: [
                    `
                        At CaptureScreen, we understand the value of flexibility and simplicity.
                        That's why our tools are designed to cater to a broad range of needs and preferences,
                        allowing you to tailor your screen capturing and recording
                        experience to your specific requirements.
                    `,
                ],
            }
        ],
    },
    {
        title: 'CaptureScreen: Your Partner in Productivity',
        p: [
            `
                We're excited about the possibilities that CaptureScreen opens up for our users.
                Whether you're an educator recording tutorials, a professional sharing presentations,
                a gamer streaming your gameplay, or anyone else who could benefit from streamlined
                screen capturing and recording, CaptureScreen is designed for you.
            `,
            `
                Start capturing and recording your screen today with CaptureScreen.
                We're excited to see what you'll create!
            `,
        ],
    },
    {
        title: 'Why Choose CaptureScreen?',
        p: [
            `
                At CaptureScreen, we believe in providing tools that are both powerful and easy to use.
                Our extension is designed to integrate seamlessly into your digital routine,
                whether you're sharing an insightful article with your team, recording a software demo,
                or capturing a moment from your favourite video.
            `,
            `
                CaptureScreen prioritizes your privacy and security.
                Your screenshots and recordings are not transmitted to any third parties unless
                you choose to share them, and all data are stored locally on your device.
            `,
        ],
    },
    {
        title: 'Wrapping Up',
        p: [
            `
                We've designed CaptureScreen with you, our user, in mind.
                Our aim is to provide a reliable, efficient, and user-friendly
                tool to help simplify your digital life. If you're ready to enhance your screen
                capturing and recording experience, install CaptureScreen today!
            `,
            <>
                We're always keen to hear feedback from our users, so if you have any questions,
                suggestions, or just want to share your experience using CaptureScreen,
                feel free to reach out to us at&nbsp;
                <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
            </>,
            `
                Remember, CaptureScreen is here to help you capture your screen, your way!
            `,
        ],
    },
]