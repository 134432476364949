import React, { FC } from 'react';
import { HowToUseWelcomeComponent } from "./components/HowToUseWelcomeComponent";
import { HowToUseDescription } from "./components/HowToUseDescription";
import { SwiftlyCaptureSection } from "../../components/SwiftlyCaptureSection";
import image from '../../images/sunrize2.png';
import { Main } from "../../components/Main";
import { Helmet } from "react-helmet";
import { getPageTitle } from "../../utils";

export const HowToUse: FC = () => {
    return (
        <>
            <Helmet>
                <title>{getPageTitle('How to use')}</title>
            </Helmet>
            <HowToUseWelcomeComponent/>
            <Main>
                <HowToUseDescription/>
                <SwiftlyCaptureSection backgroundImage={image}/>
            </Main>
        </>
    );
}