import React, { FC, useEffect, useMemo, useState } from 'react';
import { FlexBox } from "../FlexBox";
import { LoadingOutlined } from "@ant-design/icons";
import iconClasses from "../../icons/styles/index.module.scss";
import { TabItem } from "./types";
import { TabButtonsContainer } from "./components/TabButtonsContainer";

type TabsType = {
    items: TabItem[];
    selectedId?: string;
    onChange?: (id: string) => void;
    buttonsPlacing?: 'top' | 'bottom';
};

export const Tabs: FC<TabsType> = (
    {
        items,
        selectedId,
        onChange,
        buttonsPlacing = 'bottom',
    }
) => {
    const [selected, setSelected] = useState(selectedId);
    const selectedTab = useMemo(() => items.find(tab => tab.id === selected), [selected]);
    const selectedContent = selectedTab?.content;

    useEffect(() => {
        setSelected(selectedId);
    }, [selectedId]);

    return (
        <FlexBox column justify='space-between' style={{ height: '100%' }}>
            {buttonsPlacing === 'top' && (
                <TabButtonsContainer
                    items={items}
                    setSelected={setSelected}
                    selected={selected}
                    onChange={onChange}
                    isTop
                />
            )}
            {selectedContent ?? (
                <FlexBox justify='center' align='middle' style={{ height: '470px' }}>
                    <LoadingOutlined className={iconClasses.largeIcon}/>
                </FlexBox>
            )}
            {buttonsPlacing === 'bottom' && (
                <TabButtonsContainer
                    items={items}
                    setSelected={setSelected}
                    selected={selected}
                    onChange={onChange}
                />
            )}
        </FlexBox>
    );
}