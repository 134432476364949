import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import { useResizeContext } from "../../providers/ResizeProvider/ResizeProvider";
import classNames from "classnames";

type SectionImageContainerType = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const SectionImageContainer: FC<SectionImageContainerType> = (
    { className, style, ...props }
) => {
    const { isMobile } = useResizeContext();
    const sectionImageContainerClasses = classNames(className, classes.sectionImageContainer);

    return (
        <div
            className={sectionImageContainerClasses}
            style={{ transform: isMobile ? 'scale(0.8)' : '', ...style }}
            {...props}
        />
    );
}