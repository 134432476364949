import { DropdownValueType } from "../../../../components/Dropdown/types";

export const DISABLE_ID = 'disable';

export enum CheckboxValue {
    Desktop = 'desktop',
    Webcam = 'webcam',
    CurrentTub = 'currentTub',
    DesktopWebcam = 'desktopWebcam',
}

export const disabledValue: DropdownValueType = {
    id: DISABLE_ID,
    title: 'Disable',
};

export const speakers: DropdownValueType[] = [
    {
        title: 'Disable',
        id: DISABLE_ID,
    },
    {
        title: 'Enable',
        id: 'enable',
    },
];