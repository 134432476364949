import React, { FC, useRef, useState } from 'react'
import classNames from "classnames";
import classes from "./styles/index.module.scss";
import { FlexBox } from "../../../../components/FlexBox";
import { Text } from "../../../../components/Text";
import { Link } from "react-router-dom";
import { Button } from "../../../../components/Button";
import { GoogleChromeIcon } from "../../icons/GoogleChromeIcon";
import { MenuIcon } from "../../icons/MenuIcon";
import { CSSTransition } from "react-transition-group";
import { Menu } from "../Menu";
import { EdgeIcon } from "../../icons/EdgeIcon";
import { RouteURLs } from "../../const/RouteURLs";
import { LogoButton } from "../LogoButton";

export const HeaderMobile: FC = () => {
    const [isOpened, setIsOpened] = useState(false);
    const nodeRef = useRef(null);
    const headerClasses = classNames(classes.header, classes.header_mobile);

    const handleMenuOpenClick = () => {
        setIsOpened(prevState => !prevState);
    };
    const handleMenuItemsClick = () => {
        setIsOpened(false);
    };

    return (
        <header className={headerClasses}>
            <FlexBox justify='space-between'>
                <LogoButton isColoredText/>
                <FlexBox align='middle' gap='small'>
                    {!isOpened && (
                        <Link to={RouteURLs.AddToChrome} target='_blank'>
                            <Button black isColoredIcon>
                                <GoogleChromeIcon/>
                            </Button>
                        </Link>
                    )}
                    <Button link onClick={handleMenuOpenClick}>
                        <MenuIcon isOpened={isOpened}/>
                    </Button>
                </FlexBox>
            </FlexBox>
            <CSSTransition
                in={isOpened}
                timeout={300}
                nodeRef={nodeRef}
                mountOnEnter
                unmountOnExit
                classNames={{
                    enterActive: classes.menu_visible,
                    enterDone: classes.menu_visible,
                    exitActive: classes.menu_hidden,
                    exitDone:  classes.menu_hiddenDone
                }}
            >
                <div className={classes.menu} ref={nodeRef}>
                    <FlexBox column style={{ height: '100%' }}>
                        <Menu
                            onItemsClick={handleMenuItemsClick}
                        />
                        <FlexBox align='middle' gap='small'>
                            <Link to={RouteURLs.AddToChrome} target='_blank'>
                                <Button black isColoredIcon className={classes.addToChrome}>
                                    <GoogleChromeIcon/>
                                    <Text weight='semiBold'>Add to Chrome</Text>
                                </Button>
                            </Link>
                            <Link to={RouteURLs.AddToEdge} target='_blank'>
                                <Button isColoredIcon light>
                                    <EdgeIcon/>
                                </Button>
                            </Link>
                        </FlexBox>
                    </FlexBox>
                </div>
            </CSSTransition>
        </header>
    );
};