import React, { FC } from 'react';
import { HomePageWelcomeComponent } from "./components/HomePageWelcomeComponent";
import { CaptureScreenSection } from "./sections/CaptureScreenSection";
import { QuicklyShareSection } from "./sections/QuicklyShareSection";
import { CaptureScreenshotSection } from "./sections/CaptureScreenshotSection";
import { ShareScreenshotSection } from "./sections/ShareScreenshotSection";
import { EditAndAnnotateScreenshotSection } from "./sections/EditAndAnnotateScreenshotSection";
import { Collapse } from "../../components/Collapse";
import { FlexBox } from "../../../../components/FlexBox";
import { homeCollapseItems } from "./const";
import { SwiftlyCaptureSection } from "../../components/SwiftlyCaptureSection";
import { Main } from "../../components/Main";
import { Helmet } from "react-helmet";
import { getPageTitle } from "../../utils";

export const Home: FC = () => {
    return (
        <>
            <Helmet>
                <title>{getPageTitle()}</title>
            </Helmet>
            <HomePageWelcomeComponent/>
            <Main>
                <CaptureScreenSection/>
                <QuicklyShareSection/>
                <CaptureScreenshotSection/>
                <EditAndAnnotateScreenshotSection/>
                <ShareScreenshotSection/>
                <FlexBox justify='center'>
                    <FlexBox column gap='medium' align='middle' style={{ width: '70%', flexShrink: 0 }}>
                        {homeCollapseItems?.map(item => {
                            return (
                                <Collapse
                                    key={item.key}
                                    items={[item]}
                                    style={{ width: '100%' }}
                                />
                            );
                        })}
                    </FlexBox>
                </FlexBox>
                <SwiftlyCaptureSection/>
            </Main>
        </>
    );
}