import React, { FC, ReactNode } from 'react';
import classes from './styles/index.module.scss';
import { FlexBox } from "../../../FlexBox";
import classNames from "classnames";
import { PaddingBox } from "../../../PaddingBox";

type TabButtonType = {
    onClick: () => void;
    isSelected: boolean;
    children: ReactNode;
    inLine?: boolean;
    start?: boolean;
}

export const TabButton: FC<TabButtonType> = (
    { onClick, isSelected, inLine, start, children }
) => {
    const buttonClasses = classNames(classes.button, {
        [classes.selected]: isSelected,
    });

    return (
        <button onClick={onClick} style={!start ? { flexGrow: 1, width: '100px' }: {}} className={buttonClasses}>
            <PaddingBox small>
                <FlexBox column={!inLine} justify='center' align='middle' gap='tiny'>
                    {children}
                </FlexBox>
            </PaddingBox>
        </button>
    );
}