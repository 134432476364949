import React, { FC } from 'react';
import { FlexBox } from "../../../../../../components/FlexBox";
import { Title } from "../../../../components/Topography/Title";
import { homePageTexts } from "../../const";
import { Paragraph } from "../../../../components/Topography/Paragraph";
import { Section } from "../../../../components/Section";
import { useResizeContext } from "../../../../providers/ResizeProvider/ResizeProvider";
import image from '../../../../images/SharingScreenImage.png';
import { SectionImageContainer } from "../../../../components/SectionImageContainer";

export const QuicklyShareSection: FC = () => {
    const { isMobile } = useResizeContext();

    return (
        <Section style={{marginTop: 0}}>
            <FlexBox
                justify='space-between'
                align={isMobile ? 'middle' : 'top'}
                style={{ gap: isMobile ? '0' : '80px'}}
                column={isMobile}
            >
                <FlexBox column style={{ padding: isMobile ? 0 : '40px', gap: '40px' }}>
                    <FlexBox column gap='medium'>
                        <Title level={3} size={isMobile ? 'tiny' : 'small'} weight='regular' style={{ textTransform: 'uppercase' }} secondary>
                            {homePageTexts.quicklyShare.h3}
                        </Title>
                        <Title level={2} size={isMobile ? 'normal' : 'big'} weight='bold' >
                            {homePageTexts.quicklyShare.h2}
                        </Title>
                    </FlexBox>
                    <Paragraph  size='small' weight='regular'>
                        {homePageTexts.quicklyShare.p}
                    </Paragraph>
                </FlexBox>
                <SectionImageContainer>
                    <img src={image} alt=""/>
                </SectionImageContainer>
            </FlexBox>
        </Section>
    );
}