import React, { FC, ReactNode } from 'react';
import classes from './styles/index.module.scss';
import { Switch } from "antd";
import { SwitchProps } from "antd/es/switch";
import { FlexBox } from "../FlexBox";
import { PaddingBox } from "../PaddingBox";
import { Text } from "../Text";
import classNames from "classnames";

type SwitchBoxType = {
    icon?: ReactNode;
} & SwitchProps

export const SwitchBox: FC<SwitchBoxType> = (
    { onChange, title, icon, disabled, ...props }
) => {
    const switchBoxClasses = classNames({
        [classes.switchBox]: !disabled,
        [classes.switchBox_disabled]: disabled,
    });

    return (
        <PaddingBox middleVR smallHR className={switchBoxClasses}>
            <FlexBox justify='space-between' align='middle'>
                <FlexBox align='middle' gap='small'>
                    {icon}
                    <Text>
                        {title}
                    </Text>
                </FlexBox>
                <Switch defaultChecked onChange={onChange} title={title} disabled={disabled} {...props} />
            </FlexBox>
        </PaddingBox>
    );
}