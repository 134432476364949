import React, { FC } from 'react';
import { Main } from "../../components/Main";
import { FlexBox } from "../../../../components/FlexBox";
import { InfoContainer } from "../../components/InfoContainer";
import { Title } from "../../components/Topography/Title";
import { Paragraph } from "../../components/Topography/Paragraph";
import { useResizeContext } from "../../providers/ResizeProvider/ResizeProvider";
import { Helmet } from "react-helmet";
import { getPageTitle } from "../../utils";
import { UList } from "../../components/Topography/UList";
import { SUPPORT_EMAIL } from "../../const/const";

export const TermsOfService: FC = () => {
    const { isMobile } = useResizeContext();

    return (
        <Main>
            <Helmet>
                <title>{getPageTitle('Terms of Service')}</title>
            </Helmet>
            <FlexBox justify='center'>
                <InfoContainer style={{ width: isMobile ? '100%' : '80%', marginTop: '160px' }}>
                    <FlexBox column style={{ gap: isMobile ? '24px' : '40px' }}>
                        <Title size={isMobile ? "big" :'large'} weight='bold'>
                            Terms of Service
                        </Title>
                        <FlexBox column gap='middle'>
                            <Paragraph size='small' weight='regular'>
                                Please read these Terms of Service ("Terms", "Terms of Service") carefully
                                before using the CaptureScreen browser extension ("the Service")
                                operated by CaptureScreen Inc. ("us", "we", or "our").
                            </Paragraph>
                            <UList isMarkerHidden size='small' weight='regular'>
                                <FlexBox column gap='middle'>
                                    <li>
                                        <FlexBox column gap='middle'>
                                            <Title level={3} weight='bold' size='normal'>
                                                1. Acceptance of Terms
                                            </Title>
                                            <Paragraph size='small' weight='regular'>
                                                By accessing or using the Service, you agree to be bound by these Terms,
                                                all applicable laws and regulations, and agree that you are responsible
                                                for compliance with any applicable local laws. If you do not agree with
                                                any of these terms, you are prohibited from using or accessing this site.
                                            </Paragraph>
                                        </FlexBox>
                                    </li>
                                    <li>
                                        <FlexBox column gap='middle'>
                                            <Title level={3} weight='bold' size='normal'>
                                                2. Use License
                                            </Title>
                                            <Paragraph size='small' weight='regular'>
                                                Permission is granted to temporarily download one copy of the materials
                                                (information or software) on CaptureScreen's website for personal,
                                                non-commercial transitory viewing only. This is the grant of a license,
                                                not a transfer of title, and under this license, you may not:
                                            </Paragraph>
                                            <Paragraph size='small' weight='regular'>
                                                Modify or copy the materials;
                                            </Paragraph>
                                            <Paragraph size='small' weight='regular'>
                                                Use the materials for any commercial purpose, or for any public
                                                display (commercial or non-commercial);
                                            </Paragraph>
                                            <Paragraph size='small' weight='regular'>
                                                Attempt to decompile or reverse engineer any
                                                software contained on CaptureScreen's website;
                                            </Paragraph>
                                            <Paragraph size='small' weight='regular'>
                                                Remove any copyright or other proprietary notations from the materials;
                                            </Paragraph>
                                            <Paragraph size='small' weight='regular'>
                                                Transfer the materials to another person or "mirror"
                                                the materials on any other server.
                                            </Paragraph>
                                        </FlexBox>
                                    </li>
                                    <li>
                                        <FlexBox column gap='middle'>
                                            <Title level={3} weight='bold' size='normal'>
                                                3. Privacy
                                            </Title>
                                            <Paragraph size='small' weight='regular'>
                                                Your privacy is important to us. Please read our Privacy Policy for
                                                information regarding how we collect and use personal information.
                                            </Paragraph>
                                        </FlexBox>
                                    </li>
                                    <li>
                                        <FlexBox column gap='middle'>
                                            <Title level={3} weight='bold' size='normal'>
                                                4. User Responsibilities
                                            </Title>
                                            <Paragraph size='small' weight='regular'>
                                                As a user of CaptureScreen, you agree not to use the Service for any
                                                illegal activities and to respect the rights and dignity of others.
                                                This includes respecting the privacy rights of third parties while
                                                using the screenshot and screen recording functionalities.
                                            </Paragraph>
                                        </FlexBox>
                                    </li>
                                    <li>
                                        <FlexBox column gap='middle'>
                                            <Title level={3} weight='bold' size='normal'>
                                                5. Disclaimers
                                            </Title>
                                            <Paragraph size='small' weight='regular'>
                                                The Service and all included content are provided on an "as is"
                                                basis without warranty of any kind, whether express or implied.
                                                CaptureScreen Inc. takes no responsibility and assumes no liability
                                                for any User Content that you or any other user or third party
                                                posts or transmits using our Services.
                                            </Paragraph>
                                        </FlexBox>
                                    </li>
                                    <li>
                                        <FlexBox column gap='middle'>
                                            <Title level={3} weight='bold' size='normal'>
                                                6. Limitation of Liability
                                            </Title>
                                            <Paragraph size='small' weight='regular'>
                                                In no event shall CaptureScreen Inc. be liable for any direct, indirect,
                                                incidental, special, consequential, or punitive damages arising out of
                                                or in connection with your access to or use of, or inability to
                                                access or use, the Service or any materials on the Service.
                                            </Paragraph>
                                        </FlexBox>
                                    </li>
                                    <li>
                                        <FlexBox column gap='middle'>
                                            <Title level={3} weight='bold' size='normal'>
                                                7. Changes to the Terms of Service
                                            </Title>
                                            <Paragraph size='small' weight='regular'>
                                                We reserve the right to revise these Terms at any time. If we make
                                                changes, we will provide notice of such changes by posting the updated
                                                Terms on our website and changing the "Last Updated" date below.
                                            </Paragraph>
                                        </FlexBox>
                                    </li>
                                    <li>
                                        <FlexBox column gap='middle'>
                                            <Title level={3} weight='bold' size='normal'>
                                                8. Contact Us
                                            </Title>
                                            <Paragraph size='small' weight='regular'>
                                                If you have any questions about this Privacy Policy, you can contact us at
                                                &nbsp;<a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
                                            </Paragraph>
                                        </FlexBox>
                                    </li>
                                </FlexBox>
                            </UList>
                            <Paragraph size='small' weight='regular'>
                                Last Updated: 19.12.2023
                            </Paragraph>
                        </FlexBox>
                    </FlexBox>
                </InfoContainer>
            </FlexBox>
        </Main>
    );
}