import React, { FC } from 'react';
import { InfoContainer } from "../../components/InfoContainer";
import { Title } from "../../components/Topography/Title";
import { FlexBox } from "../../../../components/FlexBox";
import { Paragraph } from "../../components/Topography/Paragraph";
import { useResizeContext } from "../../providers/ResizeProvider/ResizeProvider";
import { Main } from "../../components/Main";
import { Helmet } from "react-helmet";
import { getPageTitle } from "../../utils";
import { UList } from "../../components/Topography/UList";
import { SUPPORT_EMAIL } from "../../const/const";

export const PrivacyPolicy: FC = () => {
    const { isMobile } = useResizeContext();

    return (
        <Main>
            <Helmet>
                <title>{getPageTitle('Privacy Policy')}</title>
            </Helmet>
            <FlexBox justify='center'>
                <InfoContainer style={{ width: isMobile ? '100%' : '80%', marginTop: '160px' }}>
                    <FlexBox column style={{ gap: isMobile ? '24px' : '40px' }}>
                        <Title size={isMobile ? "big" :'large'} weight='bold'>
                            Privacy Policy
                        </Title>
                        <FlexBox column gap='middle'>
                            <Paragraph size='small' weight='regular'>
                                This Privacy Policy ("Policy") applies to the CaptureScreen browser extension
                                ("the Service") operated by CaptureScreen Inc. ("us", "we", or "our") and explains
                                how we collect, use, disclose, and safeguard your information when you use our Service.
                            </Paragraph>
                            <UList isMarkerHidden size='small' weight='regular'>
                                <FlexBox column gap='middle'>
                                    <li>
                                        <FlexBox column gap='middle'>
                                            <Title level={3} weight='bold' size='normal'>
                                                1. Personal Data
                                            </Title>
                                            <Paragraph size='small' weight='regular'>
                                                We do not collect personal identifiable information when you use CaptureScreen.
                                                Your screenshots and screen recordings are stored locally on your device
                                                and are not transmitted to us or any third parties unless explicitly chosen
                                                by the user (such as for sharing or cloud storage purposes).
                                            </Paragraph>
                                        </FlexBox>
                                    </li>
                                    <li>
                                        <FlexBox column gap='middle'>
                                            <Title level={3} weight='bold' size='normal'>
                                                2. Non-Personal Data
                                            </Title>
                                            <Paragraph size='small' weight='regular'>
                                                We may collect non-personal data, such as anonymized usage statistics and
                                                technical information, to help improve the functionality and user experience
                                                of our Service. This does not include any personally identifiable information.
                                            </Paragraph>
                                        </FlexBox>
                                    </li>
                                    <li>
                                        <FlexBox column gap='middle'>
                                            <Title level={3} weight='bold' size='normal'>
                                                3. Cookies and Similar Technologies
                                            </Title>
                                            <Paragraph size='small' weight='regular'>
                                                CaptureScreen itself does not use cookies, but our website may use cookies
                                                and similar tracking technologies to improve your browsing experience.
                                                You can control the use of cookies at the individual browser level.
                                            </Paragraph>
                                        </FlexBox>
                                    </li>
                                    <li>
                                        <FlexBox column gap='middle'>
                                            <Title level={3} weight='bold' size='normal'>
                                                4. Use of Information
                                            </Title>
                                            <Paragraph size='small' weight='regular'>
                                                We use the information we collect or receive to:
                                                Enhance, improve, and further develop the Service
                                                Understand and analyze how you use our Service
                                                Develop new products, services, features, and functionalities
                                            </Paragraph>
                                            <UList isMarkerHidden size='small' weight='regular' markType='initial'>
                                                <FlexBox gap='middle' column>
                                                    <li>
                                                        Enhance, improve, and further develop the Service
                                                    </li>
                                                    <li>
                                                        Understand and analyze how you use our Service
                                                    </li>
                                                    <li>
                                                        Develop new products, services, features, and functionalities
                                                    </li>
                                                </FlexBox>
                                            </UList>
                                        </FlexBox>
                                    </li>
                                    <li>
                                        <FlexBox column gap='middle'>
                                            <Title level={3} weight='bold' size='normal'>
                                                5. Sharing of Information
                                            </Title>
                                            <Paragraph size='small' weight='regular'>
                                                We will not share your information with any third party except as
                                                described in this Privacy Policy. We may disclose your information if
                                                required to do so by law or in the good faith belief that such action
                                                is necessary to comply with a legal obligation.
                                            </Paragraph>
                                        </FlexBox>
                                    </li>
                                    <li>
                                        <FlexBox column gap='middle'>
                                            <Title level={3} weight='bold' size='normal'>
                                                6. Data Security
                                            </Title>
                                            <Paragraph size='small' weight='regular'>
                                                We have implemented appropriate security measures to protect the data
                                                we collect from loss, misuse, unauthorized access, disclosure, alteration,
                                                or destruction. However, no method of transmission over the internet is
                                                100% secure, and we cannot guarantee absolute security of your data.
                                            </Paragraph>
                                        </FlexBox>
                                    </li>
                                    <li>
                                        <FlexBox column gap='middle'>
                                            <Title level={3} weight='bold' size='normal'>
                                                7. Children's Privacy
                                            </Title>
                                            <Paragraph size='small' weight='regular'>
                                                Our Service does not address anyone under the age of 13 ("Children").
                                                We do not knowingly collect personally identifiable information from
                                                children under 13. If you are a parent or guardian and you believe that
                                                your child has provided us with personal information, please contact us.
                                            </Paragraph>
                                        </FlexBox>
                                    </li>
                                    <li>
                                        <FlexBox column gap='middle'>
                                            <Title level={3} weight='bold' size='normal'>
                                                8. Changes to this Privacy Policy
                                            </Title>
                                            <Paragraph size='small' weight='regular'>
                                                We reserve the right to revise this Privacy Policy at any time.
                                                If we make changes, we will provide notice of such changes by
                                                posting the updated Privacy Policy on our website
                                                and changing the "Last Updated" date below.
                                            </Paragraph>
                                        </FlexBox>
                                    </li>
                                    <li>
                                        <FlexBox column gap='middle'>
                                            <Title level={3} weight='bold' size='normal'>
                                                9. Contact Us
                                            </Title>
                                            <Paragraph size='small' weight='regular'>
                                                If you have any questions about this Privacy Policy, you can contact us at
                                                &nbsp;<a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
                                            </Paragraph>
                                        </FlexBox>
                                    </li>
                                </FlexBox>
                            </UList>
                            <Paragraph size='small' weight='regular'>
                                Last Updated: 19.12.2023
                            </Paragraph>
                        </FlexBox>
                    </FlexBox>
                </InfoContainer>
            </FlexBox>
        </Main>
    );
}