import React, { FC } from 'react'
import { FlexBox } from "../../../../components/FlexBox";
import { BigCheckBox } from "../../../../components/BigCheckBox";
import { CheckboxValue } from "./const";
import laptopImg from "../../../../images/AnimatedEmojies-512px-247.gif";
import { Text } from "../../../../components/Text";
import cinemaImg from "../../../../images/AnimatedEmojies-512px-402.gif";
import eyesImg from "../../../../images/AnimatedEmojies-512px-384.gif";

type RecordPageCheckboxesType = {
    checkbox: CheckboxValue | null;
    setCheckbox: React.Dispatch<React.SetStateAction<CheckboxValue | null>>;
}

export const RecordPageCheckboxes: FC<RecordPageCheckboxesType> = (
    { checkbox, setCheckbox }
) => {
    const checkIsChecked = (value: CheckboxValue) => value === checkbox;

    const handleCheckBoxChange = (value: string) => {
        setCheckbox(value as CheckboxValue);
    };

    return (
        <FlexBox column gap='medium'>
            <FlexBox gap='medium' justify='space-between'>
                <BigCheckBox
                    value={CheckboxValue.Desktop}
                    checked={checkIsChecked(CheckboxValue.Desktop)}
                    onChecked={handleCheckBoxChange}
                >
                    <img alt='' src={laptopImg} style={{ width: '48px' }} />
                    <Text weight='bold'>
                        Desktop
                    </Text>
                </BigCheckBox>
                <BigCheckBox
                    value={CheckboxValue.Webcam}
                    checked={checkIsChecked(CheckboxValue.Webcam)}
                    onChecked={handleCheckBoxChange}
                >
                    <img alt='' src={cinemaImg} style={{ width: '48px' }} />
                    <Text weight='bold'>
                        Webcam
                    </Text>
                </BigCheckBox>
            </FlexBox>
            <FlexBox gap='medium' justify='space-between'>
                <BigCheckBox
                    value={CheckboxValue.CurrentTub}
                    checked={checkIsChecked(CheckboxValue.CurrentTub)}
                    onChecked={handleCheckBoxChange}
                >
                    <img alt='' src={eyesImg} style={{ width: '48px' }} />
                    <Text weight='bold'>
                        Current Tab
                    </Text>
                </BigCheckBox>
                <BigCheckBox
                    value={CheckboxValue.DesktopWebcam}
                    checked={checkIsChecked(CheckboxValue.DesktopWebcam)}
                    onChecked={handleCheckBoxChange}
                >
                    <FlexBox>
                        <img alt='' src={laptopImg} style={{ width: '32px' }} />
                        <img alt='' src={cinemaImg} style={{ width: '32px' }} />
                    </FlexBox>
                    <Text weight='bold' style={{ textAlign: 'center'}}>
                        Desktop & Webcam
                    </Text>
                </BigCheckBox>
            </FlexBox>
        </FlexBox>
    );
};