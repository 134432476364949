import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { Section } from "../Section";
import { useScrollContext } from "../../providers/ScrollProvider/useScrollContext";
import image from '../../images/sunrize1.png';
import recordImage from '../../../../images/AnimatedEmojies-512px-402.gif';
import { Title } from "../Topography/Title";
import { FlexBox } from "../../../../components/FlexBox";
import { Link } from "react-router-dom";
import { RouteURLs } from "../../const/RouteURLs";
import { Button } from "../../../../components/Button";
import { EdgeIcon } from "../../icons/EdgeIcon";
import { GoogleChromeIcon } from "../../icons/GoogleChromeIcon";
import { Text } from "../../../../components/Text";
import { useResizeContext } from "../../providers/ResizeProvider/ResizeProvider";

const padding = 100;

type SwiftlyCaptureSectionPropsType = {
    backgroundImage?: string;
}

export const SwiftlyCaptureSection: FC<SwiftlyCaptureSectionPropsType> = (
    { backgroundImage = image }
) => {
    const sectionRef = useRef<HTMLDivElement>(null);
    const { scrollTop, clientHeight } = useScrollContext();
    const [sectionStyle, setSectionStyle] = useState<CSSProperties>();
    const { isMobile } = useResizeContext();

    useEffect(() => {
        const sectionOffsetTop = sectionRef.current?.getBoundingClientRect().top ?? 0;
        const scale = (-sectionOffsetTop + clientHeight + padding) > 0 ? (-sectionOffsetTop + clientHeight + padding)/clientHeight : 0;

        setSectionStyle({
            transform: `scale(${scale > 1 ? 1 : scale })`,
        });
    }, [sectionRef, scrollTop]);
    return (
        <>
            <div ref={sectionRef} />
            <Section style={{
                ...sectionStyle,
                backgroundImage: `url(${backgroundImage})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
            }}>
                {isMobile ? (
                    <>
                        <FlexBox column gap='normal' align='middle'>
                            <Title size='normal' weight='bold' level={5} style={{ color: 'white', textAlign: 'center' }}>
                                Swiftly Capture & Customize Screenshots or Screen Recordings with Ease
                            </Title>
                            <img src={recordImage} alt='' style={{ width: '80px' }} />
                        </FlexBox>
                        <FlexBox justify='center' align='middle' style={{
                            width: 'calc(100% - 80px)',
                            position: "absolute",
                            bottom: '40px',
                            left: '40px'
                        }}>
                            <FlexBox align='middle' gap='small'>
                                <Link to={RouteURLs.AddToChrome} target='_blank'>
                                    <Button white isColoredIcon>
                                        <GoogleChromeIcon/>
                                        <Text weight='semiBold'>Add to Chrome</Text>
                                    </Button>
                                </Link>
                                <Link to={RouteURLs.AddToEdge} target='_blank'>
                                    <Button isColoredIcon white>
                                        <EdgeIcon/>
                                    </Button>
                                </Link>
                            </FlexBox>
                        </FlexBox>
                    </>
                ) : (
                    <>
                        <Title size={isMobile ? 'normal' : 'big'} weight='bold' level={5} style={{ color: 'white', textAlign: 'center' }}>
                            Swiftly Capture & Customize Screenshots or Screen Recordings with Ease
                        </Title>
                        <FlexBox justify='space-between' align='middle' style={{
                            width: 'calc(100% - 80px)',
                            position: "absolute",
                            bottom: '40px',
                            left: '40px'
                        }}>
                            <img src={recordImage} alt='' style={{ width: '80px' }} />
                            <FlexBox align='middle' gap='small'>
                                <Link to={RouteURLs.AddToChrome} target='_blank'>
                                    <Button white isColoredIcon>
                                        <GoogleChromeIcon/>
                                        <Text weight='semiBold'>Add to Chrome</Text>
                                    </Button>
                                </Link>
                                <Link to={RouteURLs.AddToEdge} target='_blank'>
                                    <Button isColoredIcon white>
                                        <EdgeIcon/>
                                    </Button>
                                </Link>
                            </FlexBox>
                        </FlexBox>
                    </>
                )}
            </Section>
        </>
    );
}