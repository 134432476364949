import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import { useTopographyClasses } from "../hooks/useTopographyClasses";
import { UListPropsType } from "../types";
import usePropsWithoutTopography from "../hooks/usePropsWithoutTopography";
import classNames from "classnames";

export const UList: FC<UListPropsType> = (
    { className, isMarkerHidden, markType, ...props }
) => {
    const topographyClasses = useTopographyClasses(props);
    const ulProps = usePropsWithoutTopography(props);
    const ulClasses = classNames(className, topographyClasses, classes.ul, {
        [classes.ul_none]: isMarkerHidden,
        [classes.ul_initial]: markType === 'initial',
    });

    return (
        <ul className={ulClasses} {...ulProps} />
    );
}