import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import { DottedBox } from "../../../../../../components/DottedBox";
import { FlexBox } from "../../../../../../components/FlexBox";
import image from "../../../../../../images/AnimatedEmojies-512px-384.gif";

type VisibleAreaImageType = {
    isDisabled?: boolean;
}

export const VisibleAreaImage: FC<VisibleAreaImageType> = ({ isDisabled }) => {
    return (
        <DottedBox>
            <FlexBox justify='center' align='middle' className={classes.visibleAreaImage}>
                {!isDisabled && (
                    <img alt='' src={image} className={classes.image}/>
                )}
            </FlexBox>
        </DottedBox>
    );
}