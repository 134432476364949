import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import { SectionImageContainer } from "../../../../components/SectionImageContainer";
import { PopupContainer } from "../../../../components/PopupContainer";
import { Tabs } from "../../../../../../components/Tabs";
import { PopupSegmentedOptionValue } from "../../../../../Popup/const";
import { Camera, History, Like, Video } from "../../../../../../icons";
import { Text } from "../../../../../../components/Text";
import { PaddingBox } from "../../../../../../components/PaddingBox";
import { FlexBox } from "../../../../../../components/FlexBox";
import { AccentedText } from "../../../../../../components/AccentedText";
import { Button } from "../../../../../../components/Button";
import { BigButton } from "../../../../../../components/BigButton";
import { WholePageImage } from "../../../../../Popup/pages/CameraPage/components/WholePageImage";
import { Tag } from "../../../../../../components/Tag";
import { VisibleAreaImage } from "../../../../../Popup/pages/CameraPage/components/VisibleAreaImage";
import { CustomAreaImage } from "../../../../../Popup/pages/CameraPage/components/CustomAreaImage";
import { isMac, isWindows } from "../../../../../../utils";

export const PopupScreenshot: FC = () => {
    const ctrl = isMac() ? "Cmd" : isWindows() ? "Ctrl" : "";
    return (
        <SectionImageContainer>
            <PopupContainer className={classes.popupScreenshot}>
                <Tabs
                    selectedId={PopupSegmentedOptionValue.Camera}
                    items={[
                        {
                            id: PopupSegmentedOptionValue.Camera,
                            buttonContent: (
                                <>
                                    <Camera/>
                                    <Text size='small'>Screenshot</Text>
                                </>
                            ),
                            content: (
                                <PaddingBox normalTop normalHR>
                                    <FlexBox column gap='medium' justify='center' align='middle'>
                                        <Text size='large' weight='bold'>
                                            <span>Capture a </span>
                                            <AccentedText>Screenshot</AccentedText>
                                        </Text>
                                        <FlexBox gap='medium'>
                                            <BigButton
                                                className={classes.wholePageButton}
                                            >
                                                <FlexBox align='middle' justify='center' column gap='small'>
                                                    <WholePageImage/>
                                                    <Text weight='bold'>Whole page</Text>
                                                    <Tag>{ctrl} + Shift + Y</Tag>
                                                </FlexBox>
                                            </BigButton>
                                            <FlexBox column justify='space-between'>
                                                <BigButton className={classes.visibleAreaButton}>
                                                    <FlexBox align='middle' justify='center' column gap='small'>
                                                        <VisibleAreaImage/>
                                                        <Text weight='bold'>Visible area</Text>
                                                        <Tag>{ctrl} + Shift + S</Tag>
                                                    </FlexBox>
                                                </BigButton>
                                                <BigButton
                                                    className={classes.customAreaButton}
                                                >
                                                    <FlexBox align='middle' justify='center' column gap='small'>
                                                        <CustomAreaImage/>
                                                        <Text weight='bold'>Custom area</Text>
                                                        <Tag>{ctrl} + Shift + C</Tag>
                                                    </FlexBox>
                                                </BigButton>
                                            </FlexBox>
                                        </FlexBox>
                                        <FlexBox column gap='small' style={{ width: '100%' }}>
                                            <Button block>
                                                <Like/>
                                                <Text weight='semiBold'>Rate App</Text>
                                            </Button>
                                            <Button block link>
                                                <Text weight='semiBold'>Get support</Text>
                                            </Button>
                                        </FlexBox>
                                    </FlexBox>
                                </PaddingBox>
                            ),
                        },
                        {
                            id: PopupSegmentedOptionValue.Record,
                            buttonContent: (
                                <>
                                    <Video/>
                                    <Text size='small'>Video</Text>
                                </>
                            ),
                            content: null,
                            blocked: true
                        },
                        {
                            id: PopupSegmentedOptionValue.Story,
                            buttonContent: (
                                <>
                                    <History/>
                                    <Text size='small'>History</Text>
                                </>
                            ),
                            content: null,
                            blocked: true,
                        },
                    ]}
                />
            </PopupContainer>
        </SectionImageContainer>
    );
}