import React, { FC, ReactNode } from 'react';
import classes from './styles/index.module.scss';
import { PaddingBox } from "../PaddingBox";
import { Text } from "../Text";

type TagType = {
    children: ReactNode,
}

export const Tag: FC<TagType> = ({ children }) => {
    return (
        <PaddingBox smallHR className={classes.tag}>
            <Text size='tiny'>
                {children}
            </Text>
        </PaddingBox>
    );
}