import React, { FC, ReactNode } from 'react';
import classNames from "classnames";
import classes from './styles/index.module.scss'
import { FlexBox } from "../FlexBox";
import { PaddingBox } from "../PaddingBox";

type ButtonPropType = {
    children?: ReactNode,
    className?: string,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    isSelected?: boolean,
    disabled?: boolean,
    base?: boolean,
    block?: boolean,
    primary?: boolean,
    link?: boolean,
    small?: boolean,
    justify?: "center" | "start" | "end" | "space-around" | "space-between" | "space-evenly";
    isColoredIcon?: boolean;
    black?: boolean;
    light?: boolean;
    white?: boolean;
    paddingLess?: boolean;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export const Button: FC<ButtonPropType> = (
    {
        children,
        className,
        onClick,
        isSelected,
        disabled,
        block,
        link,
        primary,
        small,
        base,
        justify = 'center',
        isColoredIcon,
        black,
        light,
        white,
        paddingLess,
        ...props
    }
) => {
    const buttonClasses = classNames(className, classes.button, !base && {
        [classes.primary]: (primary || (!link)) && !disabled,
        [classes.primary_icon]: (primary || (!link)) && !disabled && !isColoredIcon,
        [classes.link]: link && !disabled,
        [classes.link_icon]: link && !disabled && !isColoredIcon,
        [classes.block]: block,
        [classes.selected]: isSelected && !disabled,
        [classes.selected_icon]: isSelected && !disabled && !isColoredIcon,
        [classes.disabled]: disabled,
        [classes.disabled_icon]: disabled && !isColoredIcon,
        [classes.disabled_link]: disabled && link,
        [classes.disabled_link_icon]: disabled && link && !isColoredIcon,
        [classes.black]: !disabled && black,
        [classes.black_icon]: !disabled && light && !isColoredIcon,
        [classes.light]: !disabled && light,
        [classes.light_icon]: !disabled && light && !isColoredIcon,
        [classes.white]: !disabled && white,
        [classes.white_icon]: !disabled && white && !isColoredIcon,
    });

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        !disabled && onClick && onClick(event);
    };

    return (
        <button
            aria-selected={isSelected}
            className={buttonClasses}
            {...props}
            onClick={handleClick}
            disabled={disabled}
        >
            {base && children}
            {!base && (
                <PaddingBox middle={!small && !paddingLess} tiny={small && !paddingLess}>
                    <FlexBox justify={justify} align='middle' gap='small'>
                        {children}
                    </FlexBox>
                </PaddingBox>
            )}
        </button>
    );
}