import React, { FC } from 'react';
import { useResizeContext } from "../../providers/ResizeProvider/ResizeProvider";
import { HeaderDesktop } from "./HeaderDesktop";
import { HeaderMobile } from "./HeaderMobile";
import classes from "./styles/index.module.scss";

export const Header: FC = () => {
    const { isMobile } = useResizeContext();

    return (
        <div className={classes.fixedHeader}>
            {isMobile ? (
                <HeaderMobile/>
            ) : (
                <HeaderDesktop/>
            )}
        </div>
    );
}