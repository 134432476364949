import React, { FC } from 'react';
import classes from "./styles/index.module.scss";
import blogImage from "../../../../images/blog.png";
import { FlexBox } from "../../../../../../components/FlexBox";
import { Title } from "../../../../components/Topography/Title";

export const BlogWelcomeComponent: FC = () => {
    return (
        <div className={classes.howToUseImage} style={{
            backgroundImage: `url(${blogImage})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        }}>
            <FlexBox justify='center' style={{ width: '100%' }} className={classes.title}>
                <Title size='large' weight='bold'>
                    Blog
                </Title>
            </FlexBox>
        </div>
    );
}