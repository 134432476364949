import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import { TextWeight, TextSize } from "./const";
import classNames from "classnames";

type TextPropType = {
    size?: keyof typeof TextSize,
    weight?: keyof typeof TextWeight,
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>

export const Text: FC<TextPropType> = (
    { size = TextSize.middle, weight = TextWeight.regular, className , ...props }
) => {
    const textClasses = classNames(className, classes.text, {
        [classes[`size_${size}`]]: !!size,
        [classes[`weight_${weight}`]]: !!weight,
    });

    return (
        <span className={textClasses} {...props} />
    );
}