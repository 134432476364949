import React, { FC } from 'react';
import { BlogWelcomeComponent } from "./components/BlogWelcomeComponent";
import { Main } from "../../components/Main";
import { SwiftlyCaptureSection } from "../../components/SwiftlyCaptureSection";
import image from "../../images/sunrize3.png";
import { BlogDescription } from "./components/BlogDescription";
import { Helmet } from "react-helmet";
import { getPageTitle } from "../../utils";

export const Blog: FC = () => {
    return (
        <>
            <Helmet>
                <title>{getPageTitle('Blog')}</title>
            </Helmet>
            <BlogWelcomeComponent/>
            <Main>
                <BlogDescription/>
                <SwiftlyCaptureSection backgroundImage={image}/>
            </Main>
        </>
    );
}