import { MenuItemType } from "./types";
import { RouteURLs } from "../../const/RouteURLs";

export const menuItems: MenuItemType[] = [
    {
        title: 'Home',
        href: RouteURLs.Home,
    },
    {
        title: 'How to use',
        href: RouteURLs.HowToUse,
    },
    {
        title: 'Blog',
        href: RouteURLs.Blog,
    },
    {
        title: 'About us',
        href: RouteURLs.AboutUs,
    },
];