import React, { FC } from 'react';
import { Main } from "../../components/Main";
import { SwiftlyCaptureSection } from "../../components/SwiftlyCaptureSection";
import image from "../../images/sunrize4.png";
import { FlexBox } from "../../../../components/FlexBox";
import { InfoContainer } from "../../components/InfoContainer";
import { Title } from "../../components/Topography/Title";
import { useResizeContext } from "../../providers/ResizeProvider/ResizeProvider";
import { Paragraph } from "../../components/Topography/Paragraph";
import { ImageContainer } from "../../components/ImageContainer";
import aboutUsLogo from '../../images/aboutUsLogo.svg';
import { aboutUsParagraphs } from "./const";
import { Helmet } from "react-helmet";
import { getPageTitle } from "../../utils";

const title = 'About Us';

export const AboutUs: FC = () => {
    const { isMobile } = useResizeContext();

    return (
        <>
            <Helmet>
                <title>{getPageTitle(title)}</title>
            </Helmet>
            {isMobile ? (
                <Main style={{ paddingTop: '28px' }}>
                    <FlexBox justify='center' gap='middle'>
                        <InfoContainer>
                            <Title size='big' weight='bold' style={{ paddingBottom: '40px' }}>
                                {title}
                            </Title>
                            <FlexBox column gap='normal'>
                                <ImageContainer>
                                    <img src={aboutUsLogo} alt=""/>
                                </ImageContainer>
                                {aboutUsParagraphs.map((aboutUsParagraph, key) => (
                                    <Paragraph size='small' weight='regular' key={key}>
                                        {aboutUsParagraph}
                                    </Paragraph>
                                ))}
                            </FlexBox>
                        </InfoContainer>
                    </FlexBox>
                    <SwiftlyCaptureSection backgroundImage={image}/>
                </Main>
            ) : (
                <Main style={{ paddingTop: '88px' }}>
                    <FlexBox justify='center' gap='middle'>
                        <InfoContainer>
                            <Title size='large' weight='bold' style={{ paddingBottom: '40px' }}>
                                {title}
                            </Title>
                            <FlexBox column gap='normal'>
                                <Paragraph size='small' weight='regular'>
                                    {aboutUsParagraphs[0]}
                                </Paragraph>
                                <Paragraph size='small' weight='regular'>
                                    {aboutUsParagraphs[1]}
                                </Paragraph>
                                <Paragraph size='small' weight='regular'>
                                    {aboutUsParagraphs[2]}
                                </Paragraph>
                            </FlexBox>
                        </InfoContainer>
                        <InfoContainer>
                            <FlexBox column gap='normal'>
                                <ImageContainer>
                                    <img src={aboutUsLogo} alt=""/>
                                </ImageContainer>
                                <Paragraph size='small' weight='regular'>
                                    {aboutUsParagraphs[3]}
                                </Paragraph>
                                <Paragraph size='small' weight='regular'>
                                    {aboutUsParagraphs[4]}
                                </Paragraph>
                                <Paragraph size='small' weight='regular'>
                                    {aboutUsParagraphs[5]}
                                </Paragraph>
                            </FlexBox>
                        </InfoContainer>
                    </FlexBox>
                    <SwiftlyCaptureSection backgroundImage={image}/>
                </Main>
            )}
        </>
    );
}