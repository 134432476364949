import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import { DottedBox } from "../../../../../../components/DottedBox";
import image from '../../../../../../images/AnimatedEmojies-512px-392.gif';
import { FlexBox } from "../../../../../../components/FlexBox";

type WholePageImageType = {
    isDisabled?: boolean;
}

export const WholePageImage: FC<WholePageImageType> = ({ isDisabled }) => {
    return (
        <DottedBox className={classes.wholePageImage}>
            {!isDisabled && (
                <FlexBox column justify='space-around' align='middle' style={{ height: '100%'}}>
                    <img alt={''} src={image} className={classes.image1}/>
                    <img alt={''} src={image} className={classes.image2}/>
                </FlexBox>
            )}
        </DottedBox>
    );
}