import React, { FC, ReactNode } from 'react';
import classes from './styles/index.module.scss';
import { FlexBox } from "../FlexBox";
import { PaddingBox } from "../PaddingBox";
import classNames from "classnames";
import { Checked } from "../../icons/Checked";

type BigCheckBoxType = {
    children: ReactNode,
    checked?: boolean,
    value: string,
    onChecked?: (value: string) => void;
}

export const BigCheckBox: FC<BigCheckBoxType> = (
    { children, checked, value, onChecked }
) => {
    const checkBoxContainerClasses = classNames(classes.checkBoxContainer, {
        [classes.checked]: checked,
    });
    const checkIconClasses = classNames(classes.checkedIcon, {
        [classes.checkedIcon_checked]: checked,
    });

    const handleClick = () => {
        onChecked && onChecked(value);
    };

    return (
        <div className={checkBoxContainerClasses} onClick={handleClick}>
            <Checked className={checkIconClasses}/>
            <PaddingBox middle>
                <FlexBox justify='center' align='middle' column gap='tiny'>
                    {children}
                </FlexBox>
            </PaddingBox>
        </div>
    );
}