import React, { FC } from 'react';
import { Title } from "../../../../components/Topography/Title";
import { FlexBox } from "../../../../../../components/FlexBox";
import { Paragraph } from "../../../../components/Topography/Paragraph";
import { ImageContainer } from "../../../../components/ImageContainer";
import howToUse1 from '../../../../images/howToUse1.png';
import howToUse2 from '../../../../images/howToUse2.png';
import howToUse3 from '../../../../images/howToUse3.png';
import howToUse4 from '../../../../images/howToUse4.png';
import howToUse5 from '../../../../images/howToUse5.png';
import { InfoContainer } from "../../../../components/InfoContainer";
import { UList } from "../../../../components/Topography/UList";

export const HowToUseDescription: FC = () => {
    return (
        <FlexBox justify='center'>
            <InfoContainer>
                <UList isMarkerHidden size='small' weight='regular'>
                    <FlexBox column gap='extraLarge'>
                        <li>
                            <FlexBox column gap='middle'>
                                <Title level={3} weight='bold' size='normal'>
                                    1. Installation
                                </Title>
                                <Paragraph size='small' weight='regular'>
                                    Before you can use CaptureScreen, you need to install it in your browser. Follow these steps:
                                </Paragraph>
                                <UList size='small' weight='regular' markType='initial'>
                                    <FlexBox column gap='small'>
                                        <li>
                                            Open your browser and go to the extensions or add-ons page.
                                        </li>
                                        <li>
                                            Search for 'CaptureScreen'.
                                        </li>
                                        <li>
                                            Click 'Add to Browser' or 'Install'.
                                        </li>
                                        <li>
                                            After installing the extension, click the puzzle icon in the browser
                                            toolbar and then click the pin button next to the CaptureScreen icon.
                                        </li>
                                    </FlexBox>
                                </UList>
                                <ImageContainer>
                                    <img src={howToUse1} alt=""/>
                                </ImageContainer>
                            </FlexBox>
                        </li>
                        <li>
                            <FlexBox column gap='middle'>
                                <Title level={3} weight='bold' size='normal'>
                                    2. Taking a Screenshot
                                </Title>
                                <UList size='small' weight='regular' markType='initial'>
                                    <FlexBox column gap='small'>
                                        <li>
                                            Click on the CaptureScreen icon in your browser toolbar.
                                        </li>
                                        <li>
                                            Choose the 'Screenshot' option from the drop-down menu.
                                        </li>
                                        <li>
                                            You will be able to choose between capturing a full page,
                                            visible area, or a specific region of the screen.
                                        </li>
                                        <li>
                                            After selecting the area, click 'Capture'.
                                        </li>
                                        <li>
                                            You can now annotate the screenshot with the editing tools provided.
                                        </li>
                                        <li>
                                            When you're finished, click 'Save'.
                                            The screenshot will be saved locally on your device.
                                        </li>
                                    </FlexBox>
                                </UList>
                                <ImageContainer>
                                    <img src={howToUse2} alt=""/>
                                </ImageContainer>
                            </FlexBox>
                        </li>
                        <li>
                            <FlexBox column gap='middle'>
                                <Title level={3} weight='bold' size='normal'>
                                    3. Recording Your Screen
                                </Title>
                                <UList size='small' weight='regular' markType='initial'>
                                    <FlexBox column gap='small'>
                                        <li>
                                            Click on the CaptureScreen icon in your browser toolbar.
                                        </li>
                                        <li>
                                            Choose the 'Screen Recorder' option from the menu.
                                        </li>
                                        <li>
                                            Select what you want to record: entire screen,
                                            application window, or browser tab.
                                        </li>
                                        <li>
                                            If you want to record audio as well, select the appropriate
                                            option (microphone, system sound, or both).
                                        </li>
                                        <li>
                                            Click 'Start Recording'.
                                        </li>
                                        <li>
                                            To end the recording, click on the CaptureScreen
                                            icon and select 'Stop Recording'.
                                        </li>
                                        <li>
                                            The screen recording will be saved locally on your
                                            device as WEBM or MP4 file of your choice.
                                        </li>
                                    </FlexBox>
                                </UList>
                                <ImageContainer>
                                    <img src={howToUse3} alt=""/>
                                </ImageContainer>
                            </FlexBox>
                        </li>
                        <li>
                            <FlexBox column gap='middle'>
                                <Title level={3} weight='bold' size='normal'>
                                    4. Managing Your Screenshots and Recordings
                                </Title>
                                <UList size='small' weight='regular' markType='initial'>
                                    <FlexBox column gap='small'>
                                        <li>
                                            Click on the CaptureScreen icon in your toolbar.
                                        </li>
                                        <li>
                                            Choose the History menu 'Manage Screenshots' or
                                            'Manage Recordings' from the menu.
                                        </li>
                                        <li>
                                            Here, you can view, download, or delete your screenshots and recordings.
                                        </li>
                                    </FlexBox>
                                </UList>
                                <ImageContainer>
                                    <img src={howToUse4} alt=""/>
                                </ImageContainer>
                            </FlexBox>
                        </li>
                        <li>
                            <FlexBox column gap='middle'>
                                <Title level={3} weight='bold' size='normal'>
                                    5. Sharing Your Screenshots and Recordings
                                </Title>
                                <UList size='small' weight='regular' markType='initial'>
                                    <FlexBox column gap='small'>
                                        <li>
                                            After taking a screenshot or recording your screen, click 'Share'.
                                        </li>
                                        <li>
                                            Choose your preferred method of sharing,
                                            such as via Google Drive or cloud storage.
                                        </li>
                                    </FlexBox>
                                </UList>
                                <ImageContainer>
                                    <img src={howToUse5} alt=""/>
                                </ImageContainer>
                            </FlexBox>
                        </li>
                        <Paragraph size='small' weight='regular'>
                            Remember: CaptureScreen is designed with a strong focus on privacy and security.
                            Your screenshots and recordings are stored locally on your device and are not shared
                            with us or any third parties unless you choose to do so.
                        </Paragraph>
                    </FlexBox>
                </UList>
            </InfoContainer>
        </FlexBox>
    );
}