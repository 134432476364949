import React, { FC } from 'react';
import { menuItems } from "./const";
import { Link, useLocation } from "react-router-dom";
import classes from './styles/index.module.scss'
import { LinkButton } from "../LinkButton";
import { Text } from "../../../../components/Text";
import { useResizeContext } from "../../providers/ResizeProvider/ResizeProvider";
import classNames from "classnames";

type MenuPropsType = {
    onItemsClick?: () => void;
}

export const Menu: FC<MenuPropsType> = ({ onItemsClick }) => {
    const { pathname } = useLocation();
    const { isMobile } = useResizeContext();
    const navClasses = classNames({
        [classes.navDesktop]: !isMobile,
        [classes.navMobile]: isMobile,
    });

    return (
        <nav className={navClasses}>
            <ul className={classes.ul}>
                {menuItems.map(item => {
                    return (
                        <li key={item.title}>
                            <Link to={item.href} onClick={onItemsClick}>
                                <LinkButton isSelected={pathname === item.href} >
                                    <Text weight={isMobile ? 'bold' : 'regular'} style={isMobile ? { fontSize: '40px' } : {}}>
                                        {item.title}
                                    </Text>
                                </LinkButton>
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </nav>
    );
}