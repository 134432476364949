import { CollapseProps } from "antd/es/collapse/Collapse";

export const homePageTexts = {
    captureScreenAndCameraSimultaneously: {
        h2: 'Capture Screen and Camera Simultaneously',
        h3: 'Screen Recording',
        p: `Effortlessly document and share your digital experiences like never before.
            Imagine the convenience of recording a demonstration while also capturing your
            own reactions and commentary through your camera. Whether you're creating engaging tutorials,
            recording live presentations, or simply showcasing anything, the ability to capture screen
            and camera simultaneously adds a dynamic touch to your content`,
    },
    quicklyShare: {
        h2: 'Quickly Share',
        h3: 'Screen Recording',
        p: `Instantly distribute your screen recordings to colleagues, 
            friends, or clients, allowing you to effortlessly convey your message and collaborate effectively. 
            Gone are the days of cumbersome file transfers and lengthy upload times. With just a few clicks, 
            you can swiftly share your screen recording, eliminating unnecessary delays and ensuring a seamless 
            communication experience. Embrace this efficient way of sharing, 
            and witness the impact it has on your productivity and collaborative efforts`,
    },
    captureScreenshotWithJustOneClick: {
        h2: 'Capture a Screenshot with Just One Click',
        h3: 'ScreenShot',
        p: `Effortlessly document and share your digital experiences like never before. 
            Imagine the convenience of recording a demonstration while also capturing 
            your own reactions and commentary through your camera. 
            Whether you're creating engaging tutorials, recording live presentations, 
            or simply showcasing anything, the ability to capture screen and camera 
            simultaneously adds a dynamic touch to your content`,
    },
    shareScreenshot: {
        h2: 'Share a Screenshot',
        h3: 'ScreenShot',
        p: `Quickly and easily distribute your captured images,
            bringing clarity and understanding to your conversations. 
            Whether you want to share a hilarious moment, seek help with a technical issue, 
            or simply showcase something interesting, sharing a screenshot becomes a breeze. 
            With just a few clicks, you can send your screenshot to friends, colleagues, or family, 
            instantly conveying your point. Capture that special moment or highlight important information`,
    },
    editAndAnnotateScreenshot: {
        h2: 'Edit and Annotate a Screenshot',
        h3: 'ScreenShot',
        p: `Enhance the impact of your screenshots with the powerful ability to edit and annotate! 
            Gone are the days of static and uninspiring screenshots. 
            With this feature, you can effortlessly add your personal touch, highlight important details, 
            and convey your message with clarity. Whether you're a student, professional, 
            or simply want to express your creativity, 
            the ability to edit and annotate your screenshots opens up a world of possibilities. 
            Add text, draw shapes, underline key points with ease`,
    },
};
export const homeCollapseItems: CollapseProps['items'] = [
    {
        key: 1,
        label: 'What is the CaptureScreen?',
        children: `The CaptureScreen is a Screenshot and Screen Recorder Extension that allows users 
            to easily take screenshots and record their screens directly from their web browser. 
            With this extension, you can document important meetings, create tutorials, 
            capture online content, and much more`,
    },
    {
        key: 2,
        label: 'How do I install the CaptureScreen Screenshot and Screen Recorder?',
        children: `You can install the extension from your browser's extension or app store. 
            Just search for "CaptureScreen", then click on "Add to browser" or "Install".
            The extension will be added to your browser, ready for use.`,
    },
    {
        key: 3,
        label: 'How do I take a screenshot using CaptureScreen?',
        children: `To take a screenshot with CaptureScreen, click on the extension icon,
            and select the 'Screenshot' option. Then, select the area of your screen that you want to capture.
            The screenshot will be saved automatically.`,
    },
    {
        key: 4,
        label: 'What is the maximum duration for screen recording in CaptureScreen?',
        children: `CaptureScreen allows unlimited screen recording duration.
            However, the length of your screen recording might be limited
            by the available storage space on your device.`,
    },
    {
        key: 5,
        label: 'Is there an option to annotate or add comments on my screenshot?',
        children: `Yes, CaptureScreen's screen capture feature includes a variety of annotation tools.
            After capturing your screenshot, you can use these tools to draw, highlight, or add text to your image.`,
    },
    {
        key: 6,
        label: 'What file formats does CaptureScreen support for screen recording?',
        children: `Screen recordings with CaptureScreen are typically saved in .WEBM or .MP4 formats,
            which is widely compatible across many devices and platforms.`,
    },
    {
        key: 7,
        label: 'Can CaptureScreen record audio while screen recording?',
        children: `Yes, a screen recorder can capture audio. You have the option to record system audio,
            microphone audio, both, or neither, depending on your needs.`,
    },
    {
        key: 8,
        label: 'Is there a shortcut for quickly starting a screen capture with CaptureScreen?',
        children: `Yes, you can use keyboard shortcuts to quickly start a screen capture.
            Please check the extension settings to see shortcut keys.`,
    },
    {
        key: 9,
        label: 'How can I share my screenshots and screen recordings from CaptureScreen?',
        children: `After taking a screenshot or finishing a screen recording with CaptureScreen,
            you can easily share the files via Google Drive cloud storage.
            The extension also provides direct links that you can share.`,
    },
    {
        key: 10,
        label: 'Is CaptureScreen compatible with all browsers?',
        children: `CaptureScreen was designed to work with most browsers,
            but for the best experience, we recommend using the latest version of Google Chrome or Microsoft Edge.`,
    },
    {
        key: 11,
        label: 'Is my data safe with CaptureScreen\'s screenshot and screen recording features?',
        children: `Absolutely. CaptureScreen is designed with a strong focus on privacy and security.
            Your screenshots and screen recordings are stored locally on your device
            unless you choose to share or upload them elsewhere.`,
    },
    {
        key: 12,
        label: 'Does CaptureScreen provide a screenshot editing tool?',
        children: `Yes, CaptureScreen provides an integrated screenshot editor which allows you
            to crop, rotate, and add text or drawings to your screenshots before saving or sharing them.`,
    },
    {
        key: 13,
        label: 'Can I pause and resume while making a screen recording?',
        children: `Yes, Screen recorder allows you to pause and resume your recording at any time,
            giving you more control over the content you capture.`,
    },
    {
        key: 14,
        label: 'How can I manage my screenshots and screen recordings in CaptureScreen?',
        children: `All screenshots and screen recordings taken with CaptureScreen are accessible within the extension.
            You can view, delete, or download your files at any time.`,
    },
    {
        key: 15,
        label: 'Is there a limit to the number of screenshots and screen recordings I can make with CaptureScreen?',
        children: `There is no specific limit set within CaptureScreen for the number
            of screenshots or screen recordings. The only limitations are your device's storage capacity.`,
    },
];