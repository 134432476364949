import React, { FC } from 'react';
import { SVG } from "../../../icons/SVG";

export const EdgeIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <SVG {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" isIcon>
        <path d="M21.6614 17.8597C21.3422 18.0248 21.0121 18.1678 20.671 18.2999C19.6366 18.685 18.5032 18.9051 17.3037 18.9051C12.8691 18.9051 9.00663 15.8569 9.00663 11.9395C9.00663 10.8391 9.64487 9.88171 10.5472 9.38652C6.53071 9.55158 5.50732 13.7331 5.50732 16.1761C5.50732 23.0977 11.8897 23.8019 13.2652 23.8019C14.0025 23.8019 15.1249 23.5818 15.7962 23.3728C15.8402 23.3618 15.8732 23.3508 15.9172 23.3287C18.5142 22.4264 20.715 20.6657 22.1566 18.3769C22.3656 18.0578 22.0025 17.6726 21.6614 17.8597Z" fill="url(#paint0_linear_635_2863)"/>
        <path opacity="0.35" d="M21.6614 17.8597C21.3422 18.0248 21.0121 18.1678 20.671 18.2999C19.6366 18.685 18.5032 18.9051 17.3037 18.9051C12.8691 18.9051 9.00663 15.8569 9.00663 11.9395C9.00663 10.8391 9.64487 9.88171 10.5472 9.38652C6.53071 9.55158 5.50732 13.7331 5.50732 16.1761C5.50732 23.0977 11.8897 23.8019 13.2652 23.8019C14.0025 23.8019 15.1249 23.5818 15.7962 23.3728C15.8402 23.3618 15.8732 23.3508 15.9172 23.3287C18.5142 22.4264 20.715 20.6657 22.1566 18.3769C22.3656 18.0578 22.0025 17.6726 21.6614 17.8597Z" fill="url(#paint1_radial_635_2863)"/>
        <path d="M9.90898 22.6245C9.07267 22.1073 8.3464 21.425 7.77419 20.6217C6.88285 19.3783 6.35465 17.8377 6.35465 16.1761C6.35465 13.7221 7.49908 11.5543 9.24874 10.1788C9.64489 9.8707 10.1621 9.55158 10.5362 9.37551C10.8333 9.23246 11.3285 8.99037 11.9888 9.00137C13.0892 9.01238 13.9255 9.57359 14.3987 10.2118C14.7618 10.707 14.9819 11.3012 14.9929 11.9615C14.9929 11.9395 17.2817 4.50069 7.48808 4.50069C3.39455 4.50069 0.00527954 8.40715 0.00527954 11.8404C0.00527954 14.0083 0.511469 15.7469 1.1387 17.0894C2.54723 20.0825 5.15521 22.4044 8.3464 23.4278C9.50183 23.8019 10.7343 24 12.0108 24C13.3313 24 14.6077 23.7799 15.8072 23.3838C15.1359 23.5928 14.4207 23.7139 13.6724 23.7139C12.2859 23.7029 11.0094 23.3067 9.90898 22.6245Z" fill="url(#paint2_linear_635_2863)"/>
        <path opacity="0.41" d="M9.90898 22.6245C9.07267 22.1073 8.3464 21.425 7.77419 20.6217C6.88285 19.3783 6.35465 17.8377 6.35465 16.1761C6.35465 13.7221 7.49908 11.5543 9.24874 10.1788C9.64489 9.8707 10.1621 9.55158 10.5362 9.37551C10.8333 9.23246 11.3285 8.99037 11.9888 9.00137C13.0892 9.01238 13.9255 9.57359 14.3987 10.2118C14.7618 10.707 14.9819 11.3012 14.9929 11.9615C14.9929 11.9395 17.2817 4.50069 7.48808 4.50069C3.39455 4.50069 0.00527954 8.40715 0.00527954 11.8404C0.00527954 14.0083 0.511469 15.7469 1.1387 17.0894C2.54723 20.0825 5.15521 22.4044 8.3464 23.4278C9.50183 23.8019 10.7343 24 12.0108 24C13.3313 24 14.6077 23.7799 15.8072 23.3838C15.1359 23.5928 14.4207 23.7139 13.6724 23.7139C12.2859 23.7029 11.0094 23.3067 9.90898 22.6245Z" fill="url(#paint3_radial_635_2863)"/>
        <path d="M14.2776 13.9532C14.2006 14.0523 13.9695 14.1843 13.9695 14.4814C13.9695 14.7235 14.1236 14.9656 14.4097 15.1637C15.7632 16.099 18.2941 15.978 18.3051 15.978C19.3505 15.978 20.2969 15.6809 21.1442 15.1967C21.5293 14.9766 21.8815 14.7015 22.2006 14.4044C23.301 13.359 23.9722 11.8845 23.9943 10.2448C24.0163 8.14305 23.246 6.74553 22.9269 6.1293C20.9461 2.23384 16.6655 0 11.9998 0C5.43031 0 0.0933125 5.28198 0.00527954 11.8294C0.049296 8.40715 3.44957 5.63411 7.49908 5.63411C7.82921 5.63411 9.69991 5.66712 11.4386 6.58047C12.9681 7.38377 13.7714 8.35213 14.3326 9.32049C14.9159 10.3219 15.0149 11.5873 15.0149 12.0825C15.0149 12.5777 14.7508 13.337 14.2776 13.9532Z" fill="url(#paint4_radial_635_2863)"/>
        <path d="M14.2776 13.9532C14.2006 14.0523 13.9695 14.1843 13.9695 14.4814C13.9695 14.7235 14.1236 14.9656 14.4097 15.1637C15.7632 16.099 18.2941 15.978 18.3051 15.978C19.3505 15.978 20.2969 15.6809 21.1442 15.1967C21.5293 14.9766 21.8815 14.7015 22.2006 14.4044C23.301 13.359 23.9722 11.8845 23.9943 10.2448C24.0163 8.14305 23.246 6.74553 22.9269 6.1293C20.9461 2.23384 16.6655 0 11.9998 0C5.43031 0 0.0933125 5.28198 0.00527954 11.8294C0.049296 8.40715 3.44957 5.63411 7.49908 5.63411C7.82921 5.63411 9.69991 5.66712 11.4386 6.58047C12.9681 7.38377 13.7714 8.35213 14.3326 9.32049C14.9159 10.3219 15.0149 11.5873 15.0149 12.0825C15.0149 12.5777 14.7508 13.337 14.2776 13.9532Z" fill="url(#paint5_radial_635_2863)"/>
        <defs>
            <linearGradient id="paint0_linear_635_2863" x1="5.50388" y1="16.5925" x2="22.221" y2="16.5925" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0C59A4"/>
                <stop offset="1" stopColor="#114A8B"/>
            </linearGradient>
            <radialGradient id="paint1_radial_635_2863" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.7373 16.7172) scale(8.94128 8.50406)">
                <stop offset="0.7166" stopOpacity="0"/>
                <stop offset="0.9459" stopOpacity="0.53"/>
                <stop offset="1"/>
            </radialGradient>
            <linearGradient id="paint2_linear_635_2863" x1="14.3173" y1="9.34014" x2="3.87513" y2="20.7141" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1B9DE2"/>
                <stop offset="0.1616" stopColor="#1595DF"/>
                <stop offset="0.6675" stopColor="#0680D7"/>
                <stop offset="1" stopColor="#0078D4"/>
            </linearGradient>
            <radialGradient id="paint3_radial_635_2863" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.68668 18.6176) rotate(-81.4656) scale(13.4266 10.8197)">
                <stop offset="0.7629" stopOpacity="0"/>
                <stop offset="0.9459" stopOpacity="0.5"/>
                <stop offset="1"/>
            </radialGradient>
            <radialGradient id="paint4_radial_635_2863" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.36922 4.38329) rotate(92.129) scale(18.9757 40.4162)">
                <stop stopColor="#35C1F1"/>
                <stop offset="0.1108" stopColor="#34C1ED"/>
                <stop offset="0.2316" stopColor="#2FC2DF"/>
                <stop offset="0.3145" stopColor="#2BC3D2"/>
                <stop offset="0.6734" stopColor="#36C752"/>
            </radialGradient>
            <radialGradient id="paint5_radial_635_2863" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.7283 7.26759) rotate(73.6745) scale(9.12464 7.44939)">
                <stop stopColor="#66EB6E"/>
                <stop offset="1" stopColor="#66EB6E" stopOpacity="0"/>
            </radialGradient>
        </defs>
    </SVG>
);