import React, { FC } from 'react'
import { FlexBox } from "../../../../components/FlexBox";
import { PaddingBox } from "../../../../components/PaddingBox";
import classes from "./styles/index.module.scss";
import { Text } from "../../../../components/Text";
import { Menu } from "../Menu";
import { Link } from "react-router-dom";
import { Button } from "../../../../components/Button";
import { EdgeIcon } from "../../icons/EdgeIcon";
import { GoogleChromeIcon } from "../../icons/GoogleChromeIcon";
import classNames from "classnames";
import { RouteURLs } from "../../const/RouteURLs";
import { LogoButton } from "../LogoButton";

export const HeaderDesktop: FC = () => {
    const headerClasses = classNames(classes.header, classes.header_desktop);

    return (
        <FlexBox justify='center'>
            <PaddingBox smallVR>
                <header className={headerClasses}>
                    <PaddingBox middle>
                        <FlexBox justify='space-between' align='middle' gap='normal'>
                            <LogoButton isColoredText/>
                            <Menu/>
                            <FlexBox align='middle' gap='small'>
                                <Link to={RouteURLs.AddToEdge} target='_blank'>
                                    <Button isColoredIcon light>
                                        <EdgeIcon/>
                                    </Button>
                                </Link>
                                <Link to={RouteURLs.AddToChrome} target='_blank'>
                                    <Button black isColoredIcon className={classes.addToChrome}>
                                        <GoogleChromeIcon/>
                                        <Text weight='semiBold'>Add to Chrome</Text>
                                    </Button>
                                </Link>
                            </FlexBox>
                        </FlexBox>
                    </PaddingBox>
                </header>
            </PaddingBox>
        </FlexBox>
    );
};