import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import { FlexBox } from "../../../FlexBox";
import { TabButton } from "../TabButton";
import { PaddingBox } from "../../../PaddingBox";
import { TabItem } from "../../types";

type TabButtonsContainerType = {
    items: TabItem[];
    setSelected: React.Dispatch<React.SetStateAction<string | undefined>>;
    selected?: string;
    onChange?: (id: string) => void;
    isTop?: boolean;
}

export const TabButtonsContainer: FC<TabButtonsContainerType> = (
    {
        items,
        setSelected,
        selected,
        onChange,
        isTop,
    }
) => {
    return (
        <PaddingBox normalHR smallBottom className={!isTop ? classes.buttonsContainer : ''}>
            <FlexBox>
                {items.map(tab => {
                    const handleTabButtonClick = () => {
                        if(!tab.blocked) {
                            setSelected(tab.id);
                            onChange && onChange(tab.id);
                        }
                    };
                    return (
                        <TabButton
                            key={tab.id}
                            onClick={handleTabButtonClick}
                            isSelected={tab.id === selected}
                            inLine={isTop}
                            start={isTop}
                        >
                            {tab.buttonContent}
                        </TabButton>
                    )
                })}
            </FlexBox>
        </PaddingBox>
    );
}