export enum TextSize {
    tiny = 'tiny',
    small = 'small',
    middle = 'middle',
    large = 'large',
}

export enum TextWeight {
    regular = 'regular',
    semiBold = 'semiBold',
    bold = 'bold',
}
