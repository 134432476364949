import React, { FC } from 'react';
import classes from './styles/index.module.scss';
import classNames from "classnames";

type AccentedTextType = {
    placing?: 'left' | 'center' | 'right';
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>

export const AccentedText: FC<AccentedTextType> = ({ placing = 'center', ...props }) => {
    const containerClasses = classNames(classes.accentedText, classes[`accentedText_${placing}`]);

    return (
        <span className={containerClasses} {...props}/>
    );
}